import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createUseStyles, Theme } from "react-jss";
import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Button } from "@mui/material";
import { KlimraTheme } from "styles/theme";
import { UPLOAD_TICKET } from "features/tickets/TicketsSaga";
import toast from "react-hot-toast";

const useStyles = createUseStyles((_theme: Theme) => ({
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
    width: "80vw",
    maxWidth: "700px",
    height: "60vh",
    maxHeight: "500px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  dottedBorder: {
    border: "2px dotted gray",
    borderRadius: "10px",
    marginTop: "20px",
    cursor: "pointer",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    background: "none",
    border: "none",
    fontSize: "16px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  fileList: {
    marginTop: "20px",
    width: "100%",
    textAlign: "left",
    listStyleType: "none",
    padding: 0,
    maxHeight: "200px",
    overflowY: "auto",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    borderBottom: "1px solid #ddd",
    alignItems: "center",
  },
  fileName: {
    flex: 1,
    textAlign: "left",
  },
  fileSize: {
    marginRight: "10px",
    fontStyle: "italic",
  },
  fileAction: {
    cursor: "pointer",
  },
  uploadButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    alignSelf: "center",
    "&:disabled": {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
  },
  warningMessage: {
    color: "red",
    marginTop: "10px",
  },
}));

export const UploadPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [fileTooLarge, setFileTooLarge] = useState(false);
  const specificLoading = useAppSelector((state) => state.app.loading);
  const dispatch = useAppDispatch();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles).filter((file) => file.type === "application/pdf");
      const largeFile = newFiles.some((file) => file.size > 10 * 1024 * 1024);
      setFileTooLarge(largeFile);
      setFiles(newFiles);

      if (newFiles.length < selectedFiles.length) {
        toast.error("Filen måste vara i PDF-format.");
      }
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files).filter((file) => file.type === "application/pdf");
    const largeFile = droppedFiles.some((file) => file.size > 10 * 1024 * 1024);
    setFileTooLarge(largeFile);
    setFiles(droppedFiles);

    if (droppedFiles.length < e.dataTransfer.files.length) {
      toast.error("Filen måste vara i PDF-format.");
    }
  };

  const handleFileRemove = (fileName: string) => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
  
    if (updatedFiles.length === 0) {
      setFileTooLarge(false);
    } else {
      setFileTooLarge(updatedFiles.some((file) => file.size > 10 * 1024 * 1024));
    }
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formatFileSize = (size: number) => {
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else if (size < 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    } else {
      return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
  };

  return (
    <div className={classes.modalOverlay} onClick={onClose}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        <button className={classes.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h1 className={classes.title}>Ladda Upp dina Biljetter</h1>

        {files.length === 0 ? (
          <div
            className={classes.dottedBorder}
            onClick={handleBoxClick}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <FontAwesomeIcon icon={faUpload} size="2x" />
            <p>Släpp dina filer här eller klicka för att välja filer</p>
            <input
              type="file"
              accept=".pdf"
              multiple
              style={{ display: "none" }}
              onChange={handleFileSelect}
              ref={fileInputRef}
            />
          </div>
        ) : (
          <ul className={classes.fileList}>
            {files.map((file) => (
              <li key={file.name} className={classes.fileItem}>
                <span className={classes.fileName}>{file.name}</span>
                <span className={classes.fileSize}>{formatFileSize(file.size)}</span>
                <span className={classes.fileAction}>
                  {specificLoading[`fileUpload#${file.name}`] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => handleFileRemove(file.name)}
                      color={"red"}
                      size="lg"
                    />
                  )}
                </span>
              </li>
            ))}
          </ul>
        )}

        {fileTooLarge && (
          <p className={classes.warningMessage}>Alla filer måste vara mindre än 2MB.</p>
        )}

        {files.length > 0 && (
          <Button
            onClick={() => dispatch({ type: UPLOAD_TICKET, payload: { files: files } })}
            disabled={specificLoading.fileUpload}
            variant="contained"
            sx={{
              color: 'white',
              borderColor: 'gray',
              backgroundColor: KlimraTheme.colors.secondary,
              '&:hover': {
                borderColor: 'gray',
                backgroundColor: 'rgb(113, 147, 148, 0.9)',
              },
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            Ladda Upp Filer
          </Button>
        )}
      </div>
    </div>
  );
};
