import { GetTicketsResponse, OriginalTicket } from "types/Response";
import { Ticket } from "types/Trip";


export function remapAndMergeTickets(tickets: GetTicketsResponse): Ticket[] {
  const ticketGroups: { [ticketNumber: string]: OriginalTicket[] } = tickets.tickets.reduce(
    (groups, ticket) => {
      if (!groups[ticket.ticketNumber]) {
        groups[ticket.ticketNumber] = [];
      }
      groups[ticket.ticketNumber].push(ticket);
      return groups;
    },
    {} as { [ticketNumber: string]: OriginalTicket[] }
  );

  const remappedTickets: Ticket[] = [];

  Object.entries(ticketGroups).forEach(([_ticketNumber, group]) => {
    const sortedTickets = group.sort(
      (a, b) => new Date(a.departureTime).getTime() - new Date(b.departureTime).getTime()
    );

    let currentTrip: OriginalTicket[] = [];
    for (let i = 0; i < sortedTickets.length; i++) {
      const currentTicket = sortedTickets[i];
      const nextTicket = sortedTickets[i + 1];

      currentTrip.push(currentTicket);

      if (
        !nextTicket ||
        (currentTicket.endStation === nextTicket.startStation && currentTicket.startStation === nextTicket.endStation) // Reversed trip detected
      ) {
        const firstTicket = currentTrip[0];
        const lastTicket = currentTrip[currentTrip.length - 1];

        const mergedTicket: Ticket = {
          ticketStatus: currentTrip.every(ticket => ticket.ticketStatus === 'full') ? 'full' : 'partial',
          ticketNumber: firstTicket.ticketNumber,
          startStation: firstTicket.startStation,
          departureTime: firstTicket.departureTime,
          endStation: lastTicket.endStation,
          arrivalTime: lastTicket.arrivalTime,
          supportStatus: firstTicket.supportStatus,
          compensationStatus: firstTicket.compensationStatus,
          trip: currentTrip.map((ticket) => ({
            startStation: ticket.startStation,
            departureTime: ticket.departureTime,
            endStation: ticket.endStation,
            arrivalTime: ticket.arrivalTime,
            ticketNumber: ticket.ticketNumber,
            travelPersonName: ticket.travelPersonName,
            uploadTime: ticket.uploadTime,
            s3TicketPath: ticket.s3TicketPath,
            trainNumber: ticket.trainNumber,
            ticketStatus: ticket.ticketStatus,
            startStationSignature: ticket.startStationSignature,
            endStationSignature: ticket.endStationSignature,
            isTrain: ticket.isTrain,
          })),
        };

        remappedTickets.push(mergedTicket);
        currentTrip = [];
      }
    }
  });

  const sortedRemappedTickets = remappedTickets.sort(
    (a, b) => new Date(b.departureTime).getTime() - new Date(a.departureTime).getTime()
  );

  return sortedRemappedTickets;
}
