import { createUseStyles, Theme } from "react-jss";
import React from "react";

const useStyles = createUseStyles((_theme: Theme) => ({
  informationSection: {
    padding: "5rem",
    marginInline: "auto",
    width: "70%",
    "& article": {
      marginBlock: "2rem",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "2rem",
    },
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "normal",
      marginTop: "20px"
    },
    "@media (max-width: 1300px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      padding: "2rem",
      width: "80%",
      "& article": {
        fontSize: "0.9rem",
      },
      "& h1": {
        fontSize: "1.5rem",
      },
      "& h2": {
        fontSize: "1rem",
      },
    },
  },
}));

interface Props {
  style?: React.CSSProperties;
}

export const CookiesInformation: React.FC<Props> = ({style}): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.informationSection} style={style}>
      <article>
        <h1>Cookies For Klimra</h1>
        <p>Dear users,</p>
        <p>This cookie policy describes how Klimra AB, organization number 559490-0986 with registered address at Drömstigen 41, uses cookies on <a href="https://www.klimra.com">https://www.klimra.com</a>.</p>

        <h2>1. WHAT IS A COOKIE?</h2>
        <p>A cookie is a small piece of data that is sent from a Website and stored in a user's browser, mobile phone or other device while the user is browsing that Website. A cookie can help the Website Provider recognize your device and/or browser the next time you visit the Website, give you access to certain features of the Website and/or record your browsing patterns. There are other technologies such as pixel tags, web bugs, web storage and other similar files and technologies that may have the same functions as cookies. In this cookie policy, we use the term "cookies" for cookies and all similar technologies.</p>

        <h2>2. WHAT TYPES OF COOKIES DO WE USE?</h2>
        <p>We use the following cookies:</p>
        <ul>
          <li><strong>Strictly necessary cookies</strong> - These cookies are strictly necessary for you to move around the website and use its functions. The website cannot function without these cookies. Strictly necessary cookies do not require your consent as they enable services that you have specifically requested and that cannot be provided otherwise.</li>
          <li><strong>Functionality cookies</strong> - These cookies enable the website to save information that you have provided in order to offer a better experience, e.g. that the website can remember choices you make, e.g. your username, language or the region you are in. Some parts of the website may not function properly without these cookies.</li>
          <li><strong>Performance cookies</strong> - These cookies are used to enable us to analyze the website's performance and design and to detect errors. These cookies also enable us to recognize that you have visited the website in the past, which pages on the website visitors visit most often and how much time visitors spend on the website. The purpose of these cookies is solely to improve website performance and your experience.</li>
        </ul>

        <h2>3. Information about our cookies</h2>
        <p>We use the following cookies for the purposes listed below:</p>
        <ul>
          <li><strong>Session cookies</strong> - Temporary cookies that expire when you close your browser. These are used to maintain your session while you navigate through our website.</li>
          <li><strong>Permanent cookies</strong> - Cookies that remain on a user's device for a certain period of time or until they are manually deleted.</li>
          <li><strong>First Party Cookies</strong> - These cookies are saved by our website and store your preferences and login details to give you a smooth experience.</li>
        </ul>
        <p>In addition to cookies, Klimra also uses pixels. Pixels are pieces of code that enable Klimra to collect information about website visitors and the actions they take during their visit. The information is used for the purpose of improving the website and creating a better experience for visitors.</p>

        <h2>4. YOUR CONSENT TO COOKIES</h2>
        <p>By clicking "I accept" on our privacy, cookies and terms of use banner when creating an account, you consent to the use of cookies.</p>
        <p>The personal data processing that takes place through cookies is based on your consent as the legal basis for the processing. This means that you can choose to allow or deny some or all cookies, with the exception of strictly necessary cookies. Most browsers also allow you to manage your cookie settings. You can set your browser so that it refuses to accept cookies or deletes certain cookies. In general, you should also be able to manage similar technologies using your browser's settings.</p>
        <p>You can review or delete cookies that have already been set via your browser. How you can do this usually differs from one browser to another. Most browsers have published instructions on how to manage cookies.</p>
        <p>Since cookies are always set on the device and browser that you use to visit the website, you must separately manage cookies on all the different devices and browsers that you use to visit the website.</p>
        <p>Please note that if you choose to block cookies, the Website's functionality may be affected.</p>

        <h2>5. PERSONAL DATA PROCESSING THROUGH COOKIES</h2>
        <p>In accordance with the tables above (see points 3 and 4), we have the following purposes and legal bases for the personal data processing that takes place via cookies:</p>
        <ul>
          <li><strong>Performance and functionality.</strong> We analyze the performance and design of the website and to detect errors. Personal data processing that takes place within the framework of these purposes is based on a legal basis.</li>
        </ul>
        <p>If you want to read more about your rights or how we process your personal data, please read our <a href="https://klimra.com/privacy-policy">Privacy Policy</a>.</p>

        <h2>6. CONTACT US</h2>
        <p>If you have any questions or comments about our use of cookies, please contact <a href="mailto:contact@klimra.com">contact@klimra.com</a>.</p>
      </article>
    </section>
  );
};
