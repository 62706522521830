import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  informationSection: {
    padding: "5rem",
    marginInline: "auto",
    width: "70%",
    "& article": {
      marginBlock: "2rem",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "2rem",
    },
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "normal",
    },
    "@media (max-width: 1300px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      padding: "2rem",
      width: "80%",
      "& article": {
        fontSize: "0.9rem",
      },
      "& h1": {
        fontSize: "1.5rem",
      },
      "& h2": {
        fontSize: "1rem",
      },
    },
  },
}));

interface Props {}

export const Terms: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.informationSection}>
      <article>
        <h1>Terms of Service</h1>
        <p>
          <strong>Last updated: October 10th, 2024</strong>
        </p>

        <h2>ACCEPTANCE OF OUR LEGAL TERMS</h2>
        <p>
          We are Klimra AB, a company registered in Sweden with the address
          Drömstigen 41, Stockholm, 16761 Stockholm.
        </p>
        <p>
          We operate the website{" "}
          <a href="https://www.klimra.com">https://www.klimra.com</a>, as well
          as all other related products and services that refer or link to these
          legal terms.
        </p>
        <p>
          You can contact us by phone at 0761608084, by email at{" "}
          <a href="mailto:contact@klimra.com">contact@klimra.com</a>, or by mail
          to Östermalmsgatan 26A, Stockholm, 11426, Sweden.
        </p>
        <p>
          These legal terms constitute a legally binding agreement between you,
          either personally or on behalf of an entity ("you"), and Klimra AB,
          concerning your access to and use of the services. You agree that by
          accessing the services, you have read, understood, and agreed to be
          bound by all these legal terms. IF YOU DO NOT AGREE TO ALL OF THESE
          LEGAL TERMS, YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
          MUST IMMEDIATELY DISCONTINUE USE.
        </p>
        <p>
          We will provide you with prior notice of any planned changes to the
          services you are using. The amended legal terms will take effect when
          published or notified to you via{" "}
          <a href="mailto:Contact@klimra.com">Contact@klimra.com</a>, as stated
          in the email notification. By continuing to use the services after the
          effective date of any changes, you agree to be bound by the revised
          terms.
        </p>
        <p>
          The services are intended for users who are at least 16 years old.
          Persons under 16 are not permitted to use or register for the
          services.
        </p>

        <h2>1. OUR SERVICES</h2>
        <p>
          The information provided in connection with the use of the services is
          not intended for distribution or use by any person or entity in any
          jurisdiction or country where such distribution or use would violate
          law or regulation, or where it would subject us to any registration
          requirement in such jurisdiction or country. Consequently, those who
          choose to access the services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
        </p>

        <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
        <h3>Our Intellectual Property</h3>
        <p>
          We are the owner or licensee of all intellectual property rights in
          our services, including all source code, databases, functionality,
          software, website design, audio, video, text, photographs, and
          graphics in the services (collectively "the Content"), as well as the
          trademarks, service marks, and logos contained therein ("the Marks").
        </p>
        <p>
          Our content and marks are protected by copyright and trademark laws
          (and various other intellectual property rights and unfair competition
          laws) and treaties in the United States and worldwide. The content and
          marks are provided in or through the services "AS IS" for your
          personal, non-commercial use or internal business purposes only.
        </p>

        <h3>Your Use of Our Services</h3>
        <p>
          Subject to your compliance with these legal terms, including the
          section "PROHIBITED ACTIVITIES" below, we grant you a non-exclusive,
          non-transferable, revocable license to:
        </p>
        <ul>
          <li>Access the services; and</li>
          <li>
            Download or print a copy of any portion of the content to which you
            have properly gained access.
          </li>
        </ul>
        <p>
          This is solely for your personal, non-commercial use or internal
          business purposes.
        </p>
        <p>
          Except as expressly stated in this section or elsewhere in our legal
          terms, no part of the services and no content or marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose without
          our express prior written permission.
        </p>
        <p>
          If you wish to use the services, content, or marks in a manner other
          than as set out in this section or elsewhere in our legal terms,
          please contact us at{" "}
          <a href="mailto:contact@klimra.com">contact@klimra.com</a>. If we
          grant you permission to publish, reproduce, or publicly display any
          part of our services or content, you must identify us as the owner or
          licensee of the services, content, or marks and ensure that all
          copyright or proprietary notices are displayed or visible when
          publishing, reproducing, or displaying our content.
        </p>
        <p>
          We reserve all rights not expressly granted to you in and to the
          services, content, and marks. Any breach of these intellectual
          property rights will constitute a material breach of our legal terms
          and your right to use our services will terminate immediately.
        </p>

        <h3>Your Submissions</h3>
        <p>
          Please review this section and the "PROHIBITED ACTIVITIES" section
          carefully before using our services to understand (a) the rights you
          grant us, and (b) your obligations when you post or upload content
          through the services.
        </p>

        <h3>Submissions</h3>
        <p>
          By directly submitting questions, comments, suggestions, ideas,
          feedback, or other information regarding the services ("Submissions")
          to us, you agree to assign to us all intellectual property rights in
          such Submissions. You agree that we shall own this submission and
          shall be entitled to its unrestricted use and dissemination for any
          lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you.
        </p>
        <p>
          You are responsible for what you post or upload: By sending us
          Submissions through any part of the services:
        </p>
        <ul>
          <li>
            You confirm that you have read and agree to our "PROHIBITED
            ACTIVITIES" and will not post, send, upload, or transmit any
            submission that is illegal, harassing, hateful, harmful, defamatory,
            obscene, bullying, abusive, discriminatory, threatening to any
            person or group, sexually explicit, false, inaccurate, fraudulent,
            or misleading;
          </li>
          <li>
            To the extent permitted by applicable law, you waive all moral
            rights to such submissions;
          </li>
          <li>
            You warrant that all such submissions are your original creation or
            that you have the necessary rights and licenses to submit such
            submissions and that you have the full authority to grant us the
            rights stated in relation to your submissions; and
          </li>
          <li>
            You warrant and represent that your submissions do not constitute
            confidential information.
          </li>
        </ul>
        <p>
          You are solely responsible for your submissions, and you expressly
          agree to reimburse us for any losses we may suffer as a result of your
          breach of (a) this section, (b) third-party intellectual property
          rights, or (c) applicable law.
        </p>

        <h3>3. USER REPRESENTATIONS</h3>
        <p>
          By using the services, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to comply with these legal
          terms; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the services through automated or
          non-human means, whether through a bot, script, or otherwise; (6) you
          will not use the services for any illegal or unauthorized purpose; (7)
          your use of the services will not violate any applicable law or
          regulation.
        </p>

        <p>
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the services
          (or any part thereof).
        </p>

        <p>
          By using the services, you agree that all information you provide
          during registration and any submissions made, including but not
          limited to claims for compensation, will be true, accurate, current,
          and complete. You agree not to submit any false, misleading, or
          fraudulent information in connection with the services, particularly
          when making claims for compensation. Providing false information may
          result in the suspension or termination of your account and may also
          expose you to legal liability. This includes potential liability to
          third parties, such as the train companies, who may take legal action
          against you for submitting false claims. Klimra AB will cooperate with
          any investigation by such third parties and reserves the right to take
          legal action to protect its interests if users knowingly submit false
          or misleading claims.
        </p>

        <h3>4. USER REGISTRATION</h3>
        <p>
          You may be required to register to use the services. You agree to keep
          your password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>

        <h3>5. PROHIBITED ACTIVITIES</h3>
        <p>
          You may not access or use the services for any purpose other than that
          for which we make the services available. The services may not be used
          in connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>

        <p>As a user of the Services, you agree not to do the following:</p>
        <ul>
          <li>
            Systematically retrieve data or other content from the services to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without our written permission.
          </li>
          <li>
            Deceive, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the services, including features that prevent or
            restrict the use or copying of any content or enforce limitations on
            the use of the services and/or the content contained therein.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm us and/or the services, in our
            opinion.
          </li>
          <li>
            Use any information obtained from the services to harass, abuse, or
            harm another person.
          </li>
          <li>
            Misuse our support services or submit false reports of abuse or
            misconduct.
          </li>
          <li>
            Use the services in a manner inconsistent with applicable laws or
            regulations.
          </li>
          <li>Engage in unauthorized framing of or linking to the services.</li>
          <li>
            Upload or transmit (or attempt to upload or transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party's uninterrupted use and enjoyment of the
            services or modifies, impairs, disrupts, alters, or interferes with
            the use, features, functions, operation, or maintenance of the
            services.
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            Remove the copyright or other proprietary rights notice from any
            content.
          </li>
          <li>
            Attempt to impersonate another user or person or use another user’s
            username.
          </li>
          <li>
            Upload or transmit (or attempt to upload or transmit) any material
            that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats ('gifs'), 1x1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as "spyware" or
            "passive collection mechanisms" or "pcms").
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on the services
            or the networks or services connected to the services.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any part of the services to you.
          </li>
          <li>
            Attempt to bypass any measures of the services designed to prevent
            or restrict access to the services, or any portion of the services.
          </li>
          <li>
            Copy or adapt the services' software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or
            in any way making up a part of the services.
          </li>
          <li>
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the services, or
            use or launch any unauthorized script or other software.
          </li>
          <li>
            Use a purchasing agent or purchasing agent to make purchases on the
            services.
          </li>
          <li>
            Make any unauthorized use of the services, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li>
            Use the services as part of any effort to compete with us or
            otherwise use the services and/or the content for any
            revenue-generating endeavor or commercial enterprise.
          </li>
          <li>
            Use the services to advertise or offer to sell goods and services.
          </li>
          <li>Sell or otherwise transfer your profile.</li>
        </ul>

        <h3>6. USER-GENERATED CONTRIBUTIONS</h3>
        <p>
          The services do not offer users the opportunity to submit or post
          content.
        </p>

        <h3>7. CONTRIBUTION LICENSE</h3>
        <p>
          You and the services agree that we may access, store, process, and use
          any information and personal data that you provide following the terms
          of the privacy policy and your choices (including settings).
        </p>

        <p>
          By submitting suggestions or other feedback regarding the services,
          you agree that we can use and share such feedback for any purpose
          without compensation to you.
        </p>

        <h2>8. MANAGEMENT OF SERVICES</h2>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the
          services for violations of these legal terms; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these legal terms, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the services
          or otherwise disable all files and content that are excessive in size
          or are in any way burdensome to our systems; and (5) otherwise manage
          the services in a manner designed to protect our rights and property
          and to facilitate the proper functioning of the services.
        </p>

        <h2>9. PRIVACY POLICY</h2>
        <p>
          We care about data privacy and security. Please review our{" "}
          <a href="https://www.klimra.com/privacy-policy">Privacy Policy</a>. By
          using the services, you agree to be bound by our Privacy Policy, which
          is incorporated into these legal terms. Please be advised that the
          services are hosted in Sweden. If you access the services from any
          other region of the world with laws or other requirements governing
          personal data collection, use, or disclosure that differ from
          applicable laws in Sweden, then through your continued use of the
          services, you are transferring your data to Sweden, and you expressly
          consent to have your data transferred to and processed in Sweden.
        </p>

        <h2>10. TERM AND TERMINATION</h2>
        <p>
          These legal terms shall remain in full force and effect while you use
          the services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
          TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES) TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT
          OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
          SOLE DISCRETION.
        </p>
        <p>
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a false or borrowed name, or the name of any third party, even
          if you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including but not limited to civil, criminal
          and injunctive relief.
        </p>

        <h2>11. MODIFICATIONS AND TERMINATION</h2>
        <p>
          We reserve the right to change, modify or remove the content of the
          Services at any time or for any reason in our sole discretion without
          notice. However, we have no obligation to update any information about
          our services. We will not be liable to you or any third party for any
          modification, price change, suspension or discontinuance of the
          Services.
        </p>
        <p>
          We cannot guarantee that the Services will always be available. We may
          experience hardware, software or other problems or need to perform
          maintenance related to the Services, resulting in interruptions,
          delays or errors. We reserve the right to change, revise, update,
          suspend, discontinue or otherwise modify the Services at any time or
          for any reason without notice to you. You agree that we shall have no
          liability whatsoever for any loss, damage or inconvenience caused by
          your inability to access or use the Services during any downtime or
          interruption of the Services. Nothing in these Legal Terms will be
          construed to obligate us to maintain and support the Services or to
          provide any corrections, updates or releases in connection therewith.
        </p>

        <h2>12. APPLICABLE LAW</h2>
        <p>
          These legal terms are governed by and interpreted in accordance with
          Swedish law, and the use of the United Nations Convention on Contracts
          for the International Sale of Goods is expressly excluded. If your
          usual place of residence is in the EU and you are a consumer, you also
          have the protection provided by the mandatory provisions of the law of
          your country of residence. Klimra AB and you both agree to submit to
          the non-exclusive jurisdiction of the Stockholm courts, which means
          that you can claim to defend your consumer protection rights with
          respect to these legal terms in Sweden, or in the EU country where you
          live.
        </p>

        <h2>13. DISPUTE RESOLUTION</h2>
        <p>
          The European Commission provides an online dispute resolution platform
          that you can access. If you would like to bring this topic to our
          attention, please contact us.
        </p>

        <h2>14. CORRECTIONS</h2>
        <p>
          There may be information about the Services that contains
          typographical errors, inaccuracies or omissions, including
          descriptions, prices, availability and various other information. We
          reserve the right to correct any errors, inaccuracies or omissions and
          to change or update the information on the Services at any time
          without notice.
        </p>

        <h2>15. DISCLAIMER</h2>
        <p>
          THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
          INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF NON-INFRINGEMENT
          OR MERCHANTABILITY. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE CONTENT OF THE SERVICES OR THE CONTENT
          OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
          DO NOT ASSUME ANY RESPONSIBILITY FOR (1) ERRORS, OMISSIONS OR
          MISREPRESENTATIONS OF CONTENT OR MATERIALS, (2) PERSONAL INJURY OR
          PROPERTY OTHER DAMAGE, OF ANY NATURE RESULTING FROM YOUR ACCESS TO AND
          USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
          SECURE SERVERS AND/OR PERSONAL INFORMATION AND/OR FINANCIAL
          INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR TERMINATION OF
          TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN
          HORSES OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH ANY OF THE
          SERVICES BY ANY THIRD PARTY, (6) ANY ERRORS OR OMISSIONS IN ANY
          CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
          AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED OR OTHERWISE
          MADE AVAILABLE THROUGH THE SERVICE. WE DO NOT ENDORSE, ENDORSE,
          GUARANTEE OR ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
          ADVERTISED OR OFFERED BY ANY THIRD PARTY THROUGH THE SERVICES, ANY
          HYPERLINKED SITE OR ANY OTHER SITE, AND WE SHALL NOT BE A PARTY TO OR
          IN ANY WAY RESPONSIBLE FOR MONITORING ANY TRANSACTIONS BETWEEN YOU AND
          THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF
          ANY PRODUCT OR SERVICE IN ANY MEANS OR IN ANY ENVIRONMENT, YOU SHOULD
          USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </p>

        <h2>16. LIMITATIONS OF LIABILITY</h2>
        <p>
          IN NO EVENT SHALL WE OR OUR DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING IF/OR
          LOSS, OTHER DAMAGES ARISING OUT OF YOUR USE OF THE SERVICES, EVEN IF
          WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          Any disputes or claims arising out of or related to your use of the
          Services will be settled outside of court through binding arbitration
          or mediation, as determined by us in our sole discretion. You agree to
          waive any right to a trial by jury or to participate in a class-action
          lawsuit or proceeding against us.
        </p>

        <h2>17. INDEMNIFICATION</h2>
        <p>
          You agree to defend, indemnify and hold harmless us, including our
          affiliates and all of our respective officers, agents, partners and
          employees, from and against any loss, damage, liability, claim or
          demand, including reasonable attorneys' fees and expenses, made by
          third parties due to or as a result of: (1) use of the Services; (2)
          breach of these Legal Terms: (3) any breach of your representations
          and warranties set forth in these Legal Terms; (4) your violation of a
          third party's rights, including but not limited to intellectual
          property rights; or (5) any apparent malicious act against any other
          user of the Services to whom you have connected through the Services.
          Notwithstanding the foregoing, we reserve the right, at your expense,
          to assume the exclusive defense and control of any matter for which
          you are obligated to indemnify us. And you agree to cooperate, at your
          expense, with our defense of such claims. We will use reasonable
          efforts to notify you of any such claim, action or proceeding that is
          subject to this indemnification when we become aware of it.
        </p>

        <h2>18. USER DATA</h2>
        <p>
          We will retain certain information that you transmit to the Services
          for the purpose of managing the performance of the Services, as well
          as information related to your use of the Services. Although we
          perform regular routine data backups, you are solely responsible for
          any data that you transmit or that relates to any activity you have
          done using the Services. You agree that we shall have no liability to
          you for any loss or corruption of such data, and you hereby waive any
          right to take any action against us arising out of such loss or
          corruption of such data.
        </p>

        <h2>19. ELECTRONIC COMMUNICATIONS, TRANSACTIONS AND SIGNATURES</h2>
        <p>
          Visiting the Services, sending us emails and completing online forms
          constitute electronic communications. You agree to receive electronic
          communications and you agree that all agreements, notices, disclosures
          and other communications we provide to you electronically, via email
          and on the Services, satisfy all legal requirements that such
          communications be in writing. YOU HEREBY CONSENT TO THE USE OF
          ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER INDICATORS AND TO
          THE ELECTRONIC DELIVERY OF NOTICES, POLICIES AND RECORDS OF
          TRANSACTIONS INITIATED OR CONCLUDED BY US OR THROUGH THE SERVICES. You
          hereby waive any rights or requirements under any laws, regulations,
          rules, or other laws of any jurisdiction that require an original
          signature or the delivery or storage of non-electronic records, or to
          payments or the granting of credit by any means other than electronic
          means.
        </p>

        <h2>20. SMS TEXT MESSAGES</h2>
        <p>
          If at any time you wish to stop receiving text messages from us,
          please delete your account or call us on 0761608084.
        </p>
        <p>
          Messaging and data rates: Please be aware that messaging and data
          charges may apply to all SMS messages sent or received. The prices are
          determined by your operator and the specifications of your mobile
          subscription.
        </p>
        <p>
          Support: If you have any questions or need help regarding our SMS
          communication, please email us at{" "}
          <a href="mailto:contact@klimra.com">contact@klimra.com</a> or call
          0761608084.
        </p>

        <h2>21. MISCELLANEOUS</h2>
        <p>
          These Legal Terms and any policies or operating rules published by us
          on or with respect to the Services constitute the entire agreement and
          understanding between you and us. Our failure to exercise or enforce
          any right or provision of these Legal Terms shall not operate as a
          waiver of such right or provision. These Legal Terms operate to the
          fullest extent permitted by law. We may assign any or all of our
          rights and obligations to others at any time. We shall not be
          responsible or liable for any loss, damage, delay or failure to act
          caused by any cause beyond our reasonable control. If any provision or
          part of a provision of these Legal Terms is determined to be illegal,
          invalid, or unenforceable, that provision or part of the provision
          will be deemed severable from these Legal Terms and will not affect
          the validity and enforceability of any remaining provisions. There is
          no joint venture, partnership, employment or agency relationship
          created between you and us as a result of these Legal Terms or use of
          the Services. You agree that these legal terms will not be construed
          against us by virtue of having drafted them. You hereby waive any
          defenses you may have based on the electronic form of these Legal
          Terms and the lack of signature by the parties to enforce these Legal
          Terms.
        </p>

        <h2>22. CONTACT US</h2>
        <p>
          To resolve a complaint regarding the Services or to obtain additional
          information regarding the use of the Services, please contact us at:
        </p>
        <p>
          Klimra AB
          <br />
          Östermalmsgatan 26A
          <br />
          Stockholm, Stockholm 11426
          <br />
          Sweden
          <br />
          Telephone: 0761608084
          <br />
          Email: <a href="mailto:contact@klimra.com">contact@klimra.com</a>
        </p>
      </article>
    </section>
  );
};
