import { requestApi } from "api";
import { UserAdditional } from "types/User";

export const getUserAdditionalAPI = async (token: string) => {
  return await requestApi(`user/account`, "GET", token);
};

export const addUserAdditionalAPI = async (token: string, userAdditional: UserAdditional) => {
  return await requestApi(`user/account`, "POST", token, { ...userAdditional});
};

export const updateUserAdditionalAPI = async (token: string, userAdditional: UserAdditional) => {
  return await requestApi(`user/account`, "PUT", token, { ...userAdditional});
};

export const removeAccountAPI = async (token: string) => {
  return await requestApi(`user/account`, "DELETE", token);
}
