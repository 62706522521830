import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ticket } from 'types/Trip';

interface TicketsState {
  tickets: Ticket[];
  loadingTicket: boolean;
  invalidTickets: string[];
}

const initialState: TicketsState = {
  tickets: [],
  loadingTicket: false,
  invalidTickets: [],
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state, action: PayloadAction<Ticket[]>) => {
      state.tickets = action.payload;
    },
    setDeleteTicket: (state, action: PayloadAction<{uploadTime: string}[]>) => {
      state.tickets = state.tickets.map(ticket => {
        const trip = ticket.trip.filter(trip => !action.payload.some(item => item.uploadTime === trip.uploadTime));
        return {
          ...ticket,
          trip,
        };
      }).filter(ticket => ticket.trip.length > 0);
    },
    setContactSupport: (state, action: PayloadAction<{message: string, ticketNumber: string}>) => {
      state.tickets = state.tickets.map(ticket => {
        if (ticket.ticketNumber === action.payload.ticketNumber) {
          return {
            ...ticket,
            supportStatus: 'pending',
          };
        }
        return ticket;
      });
    },
    setLoadingTicket: (state, action: PayloadAction<boolean>) => {
      state.loadingTicket = action.payload;
    },
    setInvalidTickets: (state, action: PayloadAction<string[]>) => {
      state.invalidTickets = action.payload;
    },
  },
});

export const { setTickets, setDeleteTicket, setContactSupport, setLoadingTicket, setInvalidTickets } = ticketsSlice.actions;
export default ticketsSlice.reducer;
