import React from "react";
import 'symbol-observable';
import { createRoot } from "react-dom/client";
import { App } from "App";
import { ThemeProvider } from "react-jss";
import { KlimraTheme } from "styles/theme";
import "./styles/index.css";
import { Toaster } from "react-hot-toast";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import store from "app/store";
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

Amplify.configure(awsConfig);

const container = document.getElementById("root");
const root = createRoot(container!);

if (process.env.REACT_APP_DEBUG === "true") {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={KlimraTheme}>
          <Toaster />
            <CookiesProvider defaultSetOptions={{path: '/'}}>
              <App />
            </CookiesProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
} else {
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={KlimraTheme}>
        <Toaster />
          <CookiesProvider defaultSetOptions={{path: '/'}}>
            <App />
          </CookiesProvider>
      </ThemeProvider>
    </Provider>
  );
}
