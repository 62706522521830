import React, { useState, useEffect } from "react";
import { createUseStyles, Theme } from "react-jss";
import { HamburgerMenu } from "./MobileNavigation";
import { DesktopNavigation } from "./DesktopNavigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/logo/logo-white.png";
import { ROUTES, ROUTES_AUTHENTICATED } from "constants/routes";
import { useAppSelector } from "app/hooks";

const useStyles = createUseStyles((_theme: Theme) => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
  },
  navbar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
    backgroundColor: 'transparent',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    cursor: 'pointer',
  },
  logoImage: {
    width: '250px',
    marginTop: '25px',
    marginLeft: '25px',
    '@media (max-width: 450px)': {
      width: '200px',
      marginTop: '25px',
      marginLeft: '15px',
    },
    '@media (max-width: 380px)': {
      width: '150px',
      marginTop: '25px',
      marginLeft: '10px',
    },
  },
}));

export const Navigation: React.FC<{}> = (): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const initialLoad = useAppSelector((state) => state.app.initialLoad);
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const userAdditionalLoaded = useAppSelector((state) => state.user.userAdditionalLoaded);
  const reRoute = useAppSelector((state) => state.app.reRoute);
  const userAdditional = useAppSelector((state) => state.user.userAdditional);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 900);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // If the user is trying to access a page that requires authentication, we want to reroute them to the login page
    if (ROUTES_AUTHENTICATED.includes(location.pathname) && initialLoad && !userDetails.id) {
      navigate(ROUTES.LOGIN);
    }
    // Checks if the user has added the additional details, otherwise we want to reroute them to the additional details page
    if (ROUTES_AUTHENTICATED.includes(location.pathname) && location.pathname !== ROUTES.USER_ADDITIONAL && initialLoad && userDetails.id && userAdditionalLoaded && !userAdditional.agreeToPowerOfAttorney) {
      navigate(ROUTES.USER_ADDITIONAL);
    }

    // If the user has added the additional details, we want to reroute them to the my trips page
    if (location.pathname === ROUTES.USER_ADDITIONAL && initialLoad && userDetails.id && userAdditionalLoaded && userAdditional.agreeToPowerOfAttorney) {
      navigate(ROUTES.MY_TRIPS);
    }

    // If the user is trying to access the login or register page and is already logged in, we want to reroute them to the my trips page
    if ((location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.REGISTER) && initialLoad && userDetails.id) {
      navigate(ROUTES.MY_TRIPS);
    }
  }, [initialLoad, userDetails, userAdditionalLoaded, location]);

  useEffect(() => {
    if (reRoute) navigate(reRoute)
  }, [reRoute])

  return (
    <div className={classes.wrapper}>
      <div className={classes.navbar}>
        <div className={classes.logo}>
          <Link to="/">
            <img src={logo} alt="Klimra Logo" className={classes.logoImage} />
          </Link>
        </div>

        {isMobile ? (
          <HamburgerMenu />
        ) : (
          <DesktopNavigation />
        )}
      </div>
    </div>
  );
};
