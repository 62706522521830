import { faCircle, faCircleCheck, faCog, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { extractTimeFromISO } from 'helpers/datetime';
import { createUseStyles, Theme } from "react-jss";
import { Ticket } from "types/Trip";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Menu, MenuItem, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { CONTACT_SUPPORT_TICKET, DELETE_TICKET, DOWNLOAD_TICKET } from 'features/tickets/TicketsSaga';
import { KlimraTheme } from 'styles/theme';


const useStyles = createUseStyles((theme: Theme) => ({
  tripContainer: {
    position: "relative",
    borderRadius: "10px",
    backgroundColor: theme.colors.backgroundPrimary,
    padding: "2rem 2rem",
    filter: "drop-shadow(0 3px 3px #0003)",
    display: "grid",
    gap: "1rem",
    alignItems: "center",
    gridAutoFlow: "column",
    gridTemplateColumns: "200px auto 200px",
    "@media (max-width: 1100px)": {
      display: "flex",
      flexDirection: "column",
    },
    "@media (max-width: 800px)": {
      padding: "2rem 1rem",
    },
    "@media (max-width: 630px)": {
      padding: "2rem 1.5rem",
    },
  },
  chipContainer: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    display: 'flex',
    gap: '10px',
  },
  chipContainerSupport: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    display: 'flex',
    gap: '10px',
    "@media (max-width: 430px)": {
      bottom: "40px",
      top: "unset",
      left: "unset",
      right: "10px",
    },
  },
  first: {
    "@media (max-width: 1100px)": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
  tripView: {
    display: "grid",
    gridTemplateColumns: "1fr max(15vw, 200px) 1fr",
    gap: "10px",
    alignItems: "center",
    marginInline: "auto",
    position: "relative",
    fontSize: "1.5rem",
    width: "100%",
    "@media (max-width: 1100px)": {
      gridTemplateColumns: "1fr max(35vw, 200px) 1fr",
      marginBottom: "2rem",
    },
    "@media (max-width: 800px)": {
      transform: "scale(0.8)",
      width: "120%",
      marginInline: "-10%",
    },
    "@media (max-width: 630px)": {
      display: "none",
    },
  },
  tripStatusBar: {
    display: "flex",
    marginInline: "auto",
    alignItems: "center",
    justifyContent: "center",
    width: "max(15vw, 200px)",
    position: "relative",
    "@media (max-width: 1100px)": {
      width: "max(35vw, 200px)",
    },
  },
  stationInfoLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    alignItems: "flex-end",
    textAlign: "right",
    "@media (max-width: 800px)": {
      fontSize: "1.5rem",
    },
  },
  stationInfoRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.5rem",
    marginLeft: "2px",
    "@media (max-width: 800px)": {
      fontSize: "1.5rem",
    },
  },
  horizontalLine: {
    borderBottom: "2px solid black",
    width: "100%",
  },
  timeAboveIconsRight: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginLeft: "-38px",
    marginTop: "-35px",
    width: "40px",
    letterSpacing: "1px",
  },
  timeAboveIconsLeft: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginRight: "-25px",
    marginTop: "-35px",
    width: "40px",
    letterSpacing: "1px",
  },
  paymentStatus: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifySelf: "flex-end",
    fontSize: "1.5rem",
    "@media (max-width: 800px)": {
      marginBottom: "2.5rem",
    },
  },
  delayBox: {
    gridRow: "2",
    gridColumn: "1 / span all",
  },
  delayStatusInfo: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2rem",
    justifyContent: "space-around",
    "& > div": {
      textAlign: "center",
      "& svg": {
        fontSize: "4rem",
      },
    },
  },
  basicInformationMobile: {
    display: "none",
    "@media (max-width: 800px)": {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  leftItem: {
    justifySelf: "start",
  },
  rightItem: {
    justifySelf: "end",
  },
  tripViewMobile: {
    display: "grid",
    gap: "10px",
    width: "100%",
    gridTemplateRows: "auto auto",
    marginTop: "3rem",
    "@media (max-width: 630px)": {
      gridTemplateColumns: "1fr 1fr",
      textAlign: "center",
    },
    "@media (min-width: 631px)": {
      display: "none",
    },
  },
  tripStatusBarMobile: {
    gridColumn: "1 / span 2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    width: "100%",
  },
  stationInfoLeftMobile: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    "@media (max-width: 630px)": {
      justifyContent: "flex-start",
    },
  },
  stationInfoRightMobile: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "right",
    "@media (max-width: 630px)": {
      justifyContent: "flex-end",
    },
  },
  settingsButton: {
    position: 'absolute',
    top: '4px',
    right: '2px',
  },
  settingsMenu: {
    backgroundColor: theme.colors.backgroundGray,
  },
}));

interface Props {
  ticket: Ticket;
  ticketType: "upcoming" | "ongoing" | "previous";
}

export const TripCard: React.FC<Props> = ({ ticket, ticketType }): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCompensationDialog, setOpenCompensationDialog] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [openRemoveTicketDialog, setOpenRemoveTicketDialog] = useState(false);
  const [confirmTrainDelay, setConfirmTrainDelay] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');

  const today = new Date();
  const departureDate = new Date(ticket.departureTime);
  const isPastTrip = departureDate < today;
  const todayDate = new Date().toLocaleDateString('sv-SE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleCompensationOpen = () => {
  //   setOpenCompensationDialog(true);
  //   handleClose();
  // };

  const handleSupportOpen = () => {
    setOpenSupportDialog(true);
    handleClose();
  };

  const handleRemoveTicketOpen = () => {
    setOpenRemoveTicketDialog(true);
    handleClose();
  };

  const handleDialogClose = () => {
    setOpenCompensationDialog(false);
    setOpenSupportDialog(false);
    setOpenRemoveTicketDialog(false);
  };

  return (
    <div className={classes.tripContainer}>
      <div className={classes.settingsButton} onClick={handleClick}>
        <Button
          variant="contained"
          color="info"
          onClick={handleClick}
          className={classes.settingsButton}
          startIcon={<FontAwesomeIcon icon={faCog} size="lg" style={{ color: 'white' }} />}
          size='small'
          sx={{
            color: 'white',
            borderColor: 'gray',
            backgroundColor: KlimraTheme.colors.secondary,
            '&:hover': {
              borderColor: 'gray',
              backgroundColor: 'rgb(113, 147, 148, 0.9)',
            },
          }}
        >
          Hantera Biljett
        </Button>
      </div>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.settingsMenu }}
      >
        {/* <MenuItem onClick={handleCompensationOpen}>
          Ansök om kompensation
        </MenuItem> */}
        <MenuItem onClick={handleSupportOpen}>
          Kontakta support
        </MenuItem>
        <MenuItem onClick={() => dispatch({ type: DOWNLOAD_TICKET, payload: { s3Path: ticket.trip[0].s3TicketPath }})} color="primary">
          Ladda ner biljett
        </MenuItem>
        <MenuItem onClick={handleRemoveTicketOpen}>
          Ta bort biljett
        </MenuItem>
      </Menu>

      {/* Ansök om kompensation Dialog */}
      <Dialog open={openCompensationDialog} onClose={handleDialogClose}>
        <DialogTitle>Ansök om kompensation</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill manuellt ansöka om kompensation för denna biljett?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Ytterligare information"
            fullWidth
            multiline
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmTrainDelay}
                onChange={(e) => setConfirmTrainDelay(e.target.checked)}
                color="primary"
              />
            }
            label="Jag bekräftar att tåget var försenat minst 60 minuter"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={() => alert('Ansökan skickad!')} color="primary">
            Skicka
          </Button>
        </DialogActions>
      </Dialog>

      {/* Kontakta support Dialog */}
      <Dialog open={openSupportDialog} onClose={handleDialogClose}>
        <DialogTitle>Kontakta support</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill kontakta support för denna biljett?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Ytterligare information"
            fullWidth
            multiline
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={() => { setOpenSupportDialog(false); dispatch({ type: CONTACT_SUPPORT_TICKET, payload: { message: additionalInfo, ticketNumber: ticket.ticketNumber } })}} color="primary">
            Skicka
          </Button>
        </DialogActions>
      </Dialog>

      {/* Ta bort biljett Dialog */}
      <Dialog open={openRemoveTicketDialog} onClose={handleDialogClose}>
        <DialogTitle>Ta bort biljett</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill ta bort denna biljett? Denna åtgärd kan inte ångras.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={() => { setOpenRemoveTicketDialog(false); dispatch({ type: DELETE_TICKET, payload: ticket.trip.map(t => ({ uploadTime: t.uploadTime }))} ) }} color="secondary">
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.chipContainer}>
        {isPastTrip && (
          <Tooltip title={`Klimra teamet håller på att behandla denna biljett manuellt. Alla biljetter innan ${todayDate} behövs manuellt verifieras.`}>
            <Chip
              label="Biljetten behandlas"
              color="default"
              variant="outlined"
              size="small"
              icon={<FontAwesomeIcon icon={faCog} />} 
            />
          </Tooltip>
        )}
        {ticket.ticketStatus !== "full" && (
          <Tooltip title="Denna biljett saknar information och bearbetas manuellt av Klimra-teamet.">
            <Chip
              label="Saknar information"
              color="error"
              variant={"outlined"}
              size="small"
              icon={<FontAwesomeIcon icon={faExclamationCircle} />}
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.chipContainerSupport}>
        {ticket.supportStatus !== "none" && (
          <Tooltip title={
            ticket.supportStatus === "pending"
              ? "Support är medveten om denna biljett och kommer att kontakta dig via email."
              : "Support har kontaktat dig via email."
          }>
            <Chip
              label={ticket.supportStatus === "pending" ? "Support ärende pågår" : "Support kontaktad dig via email"}
              color="warning"
              variant={"outlined"}
              size="small"
              icon={<FontAwesomeIcon icon={faExclamationCircle} />}
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.first}>
        <h2>
          <strong>{ticket.departureTime.split("T")[0]}</strong>
        </h2>
        <p>
          {ticket.startStation} - {ticket.endStation}
        </p>
        <p>
          tåg {ticket.trip.map((trip) => trip.trainNumber).join(", ")}
        </p>
        <p>
          {ticket.ticketStatus === "full" && false ? <><strong>0 min </strong>försenad</> : <></>}
        </p>
      </div>
      
      <div className={classes.basicInformationMobile}>
        <h2 className={classes.leftItem}>
          <strong>{ticket.departureTime.split("T")[0]}</strong>
        </h2>
        <p className={classes.rightItem}>
          tåg {ticket.trip.map((trip) => trip.trainNumber).join(", ")}
        </p>
        <p className={classes.leftItem}>
          {ticket.startStation} - {ticket.endStation}
        </p>
        <p className={classes.rightItem}>
          {ticket.ticketStatus === "full" && false ? <><strong>0 min </strong>försenad</> : <></>}
        </p>
      </div>
      
      <div className={classes.tripView}>
        <div className={classes.stationInfoLeft}>
          <strong>{ticket.startStation}</strong>
        </div>

        <div className={classes.tripStatusBar}>
          <p className={classes.timeAboveIconsLeft}>{extractTimeFromISO(ticket.departureTime)}</p>
          <FontAwesomeIcon icon={faCircle} />
          <div className={classes.horizontalLine} />
          {ticketType === "upcoming" ? (
            <FontAwesomeIcon icon={faCircle} />
          ) : ticketType === "previous" ? (
            <FontAwesomeIcon icon={faCircleCheck} />
          ) : (
            <FontAwesomeIcon icon={faSpinner} />
          )}
          <p className={classes.timeAboveIconsRight}>{extractTimeFromISO(ticket.arrivalTime)}</p>
        </div>

        <div className={classes.stationInfoRight}>
          <strong>{ticket.endStation}</strong>
        </div>
      </div>

      <div className={classes.tripViewMobile}>
        <div className={classes.tripStatusBarMobile}>
          <p className={classes.timeAboveIconsLeft}>{extractTimeFromISO(ticket.departureTime)}</p>
          <FontAwesomeIcon icon={faCircle} />
          <div className={classes.horizontalLine} />
          {ticketType === "upcoming" ? (
            <FontAwesomeIcon icon={faCircle} />
          ) : ticketType === "previous" ? (
            <FontAwesomeIcon icon={faCircleCheck} />
          ) : (
            <FontAwesomeIcon icon={faSpinner} />
          )}
          <p className={classes.timeAboveIconsRight}>{extractTimeFromISO(ticket.arrivalTime)}</p>
        </div>

        <div className={classes.stationInfoLeftMobile}>
          <strong>{ticket.startStation}</strong>
        </div>

        <div className={classes.stationInfoRightMobile}>
          <strong>{ticket.endStation}</strong>
        </div>

      </div>

      <div className={classes.paymentStatus}>
      <FontAwesomeIcon icon={faCog} />
        {/* <p>{ticket.compensationStatus === "pending" ? "Kompensation pågår" : ticket.compensationStatus === "done" ? "Kompensation utförd" : "Ingen kompensation"}</p> */}
        Pågående Process
      </div>
    </div>
  );
};
