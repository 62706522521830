import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAdditional, UserDetails } from 'types/User';

interface UserState {
  isAuthenticated: boolean;
  userDetails: UserDetails;
  userAdditional: UserAdditional;
  userAdditionalLoaded: boolean;
  error: string | null;
}

const initialState: UserState = {
  isAuthenticated: false,
  userDetails: {
    id: '',
    username: '',
    email: {
      email: '',
      verified: false,
    },
    token: '',
  },
  userAdditional: {
    firstname: '',
    lastname: '',
    phoneNumber: '',
    personalIdentificationNumber: '',
    agreeToTerms: false,
    agreeToPowerOfAttorney: false,
    linkedEmail: false,
  },
  userAdditionalLoaded: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails(state, action: PayloadAction<UserDetails>) {
      state.userDetails = action.payload;
      state.isAuthenticated = true;
    },
    setLogOut(state) {
      state.userDetails = initialState.userDetails;
      state.isAuthenticated = false;
    },
    setUserAdditional(state, action: PayloadAction<UserAdditional>) {
      state.userAdditional = action.payload;
    },
    setUserAdditionalLoaded(state) {
      state.userAdditionalLoaded = true
    }
  },
});

export const { setUserDetails, setLogOut, setUserAdditional, setUserAdditionalLoaded } = userSlice.actions;
export default userSlice.reducer;
