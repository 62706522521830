const awsConfig = {
  aws_project_region: "eu-north-1",
  aws_cognito_region: "eu-north-1",
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
    responseType: "code",
  }
};

export default awsConfig;
