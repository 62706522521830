import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { getCurrentUser, signInWithRedirect, fetchAuthSession, GetCurrentUserOutput, AuthSession, signOut } from "aws-amplify/auth";
import { setUserAdditional, setUserAdditionalLoaded, setUserDetails } from './UserSlice';
import { UserAdditional, UserDetails } from 'types/User';
import { setInitialLoad, setLoading, setReRoute } from 'features/app/AppSlice';
import { addUserAdditionalAPI, getUserAdditionalAPI, removeAccountAPI, updateUserAdditionalAPI } from './UserAPI';
import { RootState } from 'app/store';
import { AddUserAdditionalResponse, GetUserAdditionalResponse, RemoveAccountResponse } from 'types/Response';
import toast from 'react-hot-toast';
import { ROUTES } from 'constants/routes';

function* googleLogin() {
  try {
    yield put(setLoading({ key: 'googleLogin', isLoading: true }));
    yield call(signInWithRedirect, { provider: 'Google' });
  } catch (error) {
    console.error(error);
  } finally {
  }
}

function* googleLogout() {
  try {
    yield call(signOut, { global: true });
  } catch (error) {
    console.error(error);
  }
}

function* getUser() {
  try {
    yield put(setLoading({ key: 'authenticating', isLoading: true }));
    const session: AuthSession = yield fetchAuthSession();
    const user: GetCurrentUserOutput = yield getCurrentUser();

    const idTokenPayload = session.tokens?.idToken?.payload;

    if (!idTokenPayload || !idTokenPayload.email || !session.tokens?.idToken) {
      throw new Error('Invalid session or token data');
    }

    const userObj: UserDetails = {
      id: user.userId,
      username: user.username,
      email: {
        email: session.tokens?.idToken?.payload.email as string,
        verified: session.tokens?.idToken?.payload.email_verified as boolean,
      },
      token: session.tokens?.idToken.toString() as string,
    }

    yield put(setUserDetails(userObj));
    yield put(setInitialLoad());
    yield put(setLoading({ key: 'authenticating', isLoading: false }));

    yield put({ type: GET_USER_ADDITIONAL });

  } catch (error) {
    yield put(setInitialLoad());
    yield put(setLoading({ key: 'authenticating', isLoading: false }));
  } finally {
  }
}

function* getUserAdditional() {
  try {
    const token: string = yield select((state: RootState) => state.user.userDetails.token);
    const userObj: GetUserAdditionalResponse = yield getUserAdditionalAPI(token);

    if (userObj.userFound) {
      yield put(setUserAdditional(userObj.userDetails));
      yield put(setUserAdditionalLoaded());
    } else {
      yield put(setUserAdditionalLoaded());
    }
  } catch (error) {
    console.error(error);
  } finally {
  }
}

function* addUserAdditional() {
  try {
    yield put(setLoading({ key: 'addUserAdditional', isLoading: true }));
    const token: string = yield select((state: RootState) => state.user.userDetails.token);
    const userAdditionalInfo: UserAdditional = yield select((state: RootState) => state.user.userAdditional);

    const userObj: AddUserAdditionalResponse = yield addUserAdditionalAPI(token, userAdditionalInfo);

    if (userObj.success) {
      yield put(setUserAdditional(userObj.userDetails));
      yield put(setReRoute(ROUTES.MY_TRIPS));
    } else {
      toast.error('Misslyckades att spara användaruppgifter');
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setLoading({ key: 'addUserAdditional', isLoading: false }));
  }
}

function* updateUserAdditional() {
  try {
    yield put(setLoading({ key: 'updateUserAdditional', isLoading: true }));
    const token: string = yield select((state: RootState) => state.user.userDetails.token);
    const userAdditionalInfo: UserAdditional = yield select((state: RootState) => state.user.userAdditional);

    const userObj: AddUserAdditionalResponse = yield updateUserAdditionalAPI(token, userAdditionalInfo);

    if (userObj.success) {
      yield put(setUserAdditional(userObj.userDetails));
      toast.success('Användaruppgifter uppdaterade');
    } else {
      toast.error('Misslyckades att spara användaruppgifter');
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setLoading({ key: 'updateUserAdditional', isLoading: false }));
  }
}

function* removeAccount() {
  try {
    yield put(setLoading({ key: 'removeAccount', isLoading: true }));
    const token: string = yield select((state: RootState) => state.user.userDetails.token);

    const response: RemoveAccountResponse = yield removeAccountAPI(token);

    if (response.success) {
      toast.success('Konto borttaget');
      yield delay(500)
      yield put({ type: GOOGLE_LOGOUT_REQUEST });
    } else {
      toast.error('Misslyckades att ta bort konto');
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setLoading({ key: 'removeAccount', isLoading: false }));
  }
}

export default function* UserSaga() {
  yield all([
    takeLatest(GOOGLE_LOGIN_REQUEST, googleLogin),
    takeLatest(GOOGLE_LOGOUT_REQUEST, googleLogout),
    takeLatest(GET_USER, getUser),
    takeLatest(GET_USER_ADDITIONAL, getUserAdditional),
    takeLatest(ADD_USER_ADDITIONAL, addUserAdditional),
    takeLatest(UPDATE_USER_ADDITIONAL, updateUserAdditional),
    takeLatest(REMOVE_ACCOUNT, removeAccount),
  ]);
}

export const GOOGLE_LOGIN_REQUEST = 'user/GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGOUT_REQUEST = 'user/GOOGLE_LOGOUT_REQUEST';
export const GET_USER = 'user/GET_USER';
export const GET_USER_ADDITIONAL = 'user/GET_USER_ADDITIONAL';
export const ADD_USER_ADDITIONAL = 'user/ADD_USER_ADDITIONAL';
export const UPDATE_USER_ADDITIONAL = 'user/UPDATE_USER_ADDITIONAL';
export const REMOVE_ACCOUNT = 'user/REMOVE_ACCOUNT';
