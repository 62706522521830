import { ClickableAccordion } from "Components/Common/ClickableAccordion";
import { createUseStyles, Theme } from "react-jss";
import { faUserCircle, faSignOutAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { GOOGLE_LOGOUT_REQUEST, REMOVE_ACCOUNT, UPDATE_USER_ADDITIONAL } from "features/user/UserSaga";
import { LoadingIcon } from "Components/Misc/LoadingIcon";
import { setUserAdditional } from "features/user/UserSlice";
import { useState } from "react";
import { KlimraTheme } from "styles/theme";
import { useStylesAdditionalDetails } from "pages/additional-details/AdditionalDetails";
import Space from "Components/Common/Space";

const useStyles = createUseStyles((theme: Theme) => ({
  faqContainer: {
    "& h1 ": {
      margin: "0",
    },
    padding: "5rem 30vmin",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    "@media (max-width: 1200px)": {
      padding: "2rem 20vmin",
    },
    "@media (max-width: 1000px)": {
      padding: "2rem 10vmin",
    },
    "@media (max-width: 850px)": {
      padding: "2rem 3vmin",
    },
  },
  headerSubtitle: {
    fontSize: "1rem",
    marginTop: "0.5rem",  
    color: "red"
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '70px 100px',
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    width: '60vw',
    maxWidth: '400px',
    margin: '30px 0 0px 0',
    "@media (max-width: 900px)": {
      width: '85%',
      padding: '40px 20px',
    },
    "@media (max-width: 550px)": {
      width: '90%',
      padding: '40px 20px',
    },
    "@media (max-width: 400px)": {
      padding: '20px 10px',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      width: '120%',
      marginLeft: '-10%',
    },
    textAlign: 'center',
  }
}));

const AccountModifyAdditionalDetails: React.FC<{}> = ({  }): JSX.Element => {
  const classes = useStylesAdditionalDetails();
  const otherClasses = useStyles();
  const dispatch = useAppDispatch();

  const userAdditional = useAppSelector((state) => state.user.userAdditional);
  const isLoading = useAppSelector((state) => state.app.loading["updateUserAdditional"]);

  const [errors, setErrors] = useState({
    firstname: false,
    lastname: false,
    phoneNumber: false,
    personalIdentificationNumber: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    dispatch(
      setUserAdditional({
        ...userAdditional,
        [name]: type === 'checkbox' ? checked : value,
      })
    );
  };

  const handleSubmit = () => {
    const newErrors = {
      firstname: !userAdditional.firstname,
      lastname: !userAdditional.lastname,
      phoneNumber: !userAdditional.phoneNumber,
      personalIdentificationNumber: !userAdditional.personalIdentificationNumber,
    };

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => !error);

    if (isValid) {
      dispatch({ type: UPDATE_USER_ADDITIONAL });
    }
  };

  return (
    <div className={otherClasses.wrapper}>
      <div className={otherClasses.container}>
        <Typography variant="h4" gutterBottom color={"black"}>
          Updatera dina uppgifter
        </Typography>
        <TextField
          label="Förnamn"
          name="firstname"
          value={userAdditional.firstname}
          onChange={handleChange}
          error={errors.firstname}
          helperText={errors.firstname ? 'Förnamn är obligatoriskt' : ''}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Efternamn"
          name="lastname"
          value={userAdditional.lastname}
          onChange={handleChange}
          error={errors.lastname}
          helperText={errors.lastname ? 'Efternamn är obligatoriskt' : ''}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Telefonnummer"
          name="phoneNumber"
          value={userAdditional.phoneNumber}
          onChange={handleChange}
          error={errors.phoneNumber}
          helperText={errors.phoneNumber ? 'Telefonnummer är obligatoriskt' : ''}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Personnummer"
          name="personalIdentificationNumber"
          value={userAdditional.personalIdentificationNumber}
          onChange={handleChange}
          error={errors.personalIdentificationNumber}
          helperText={errors.personalIdentificationNumber ? 'Personnummer är obligatoriskt' : ''}
          fullWidth
          margin="normal"
        />

        <Box mt={2}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: KlimraTheme.colors.buttonPrimary,
              color: KlimraTheme.colors.textPrimary,
              width: '150px',
            }}
          >
            {isLoading ? <LoadingIcon isLoading={true} className={classes.loadingIcon} /> : 'Fortsätt'}
          </Button>
        </Box>
      </div>
    </div>
  );
};

const AccountLogout: React.FC<{}> = ({  }): JSX.Element => {
  const dispatch = useAppDispatch();
  const otherClasses = useStyles();

  return (
    <div className={otherClasses.wrapper}>
      <div className={otherClasses.container}>
        <Typography variant="h4" gutterBottom>
          Logga ut
        </Typography>
        <Typography variant="body1" gutterBottom>
          Vill du logga ut från ditt konto?
        </Typography>
        <Space type={"large"} />
        <Button variant="contained" color="error" onClick={() => dispatch({ type: GOOGLE_LOGOUT_REQUEST })} sx={{ width: "200px"}}>
          Logga ut
        </Button>
      </div>
    </div>
  )
}

const AccountDelete: React.FC<{}> = ({ }): JSX.Element => {
  const dispatch = useAppDispatch();
  const otherClasses = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch({ type: REMOVE_ACCOUNT });
    handleClose();
  };

  return (
    <div className={otherClasses.wrapper}>
      <div className={otherClasses.container}>
        <Typography variant="h4" gutterBottom>
          Radera ditt konto
        </Typography>
        <Typography variant="body1" gutterBottom>
          Vill du verkligen ta bort ditt konto? Om du tar bort ditt konto kommer du förlora följande förmåner:
        </Typography>
        
        {/* Benefits Section */}
        <Paper elevation={3} sx={{ padding: '20px', marginBottom: '40px', marginTop: '40px' }}>
          <Typography variant="h6" gutterBottom>
            Varför du borde behålla ditt konto:
          </Typography>
          <Box component="ul" sx={{ padding: 0, margin: 0, listStyleType: 'none', textAlign: "left" }}>
            <Box component="li" sx={{ marginBottom: '10px' }}>
              <Typography variant="body2">
                <strong>Snabb kompensation:</strong> Klimra säkerställer att du får din ersättning inom 72 timmar.
              </Typography>
            </Box>
            <Box component="li" sx={{ marginBottom: '10px' }}>
              <Typography variant="body2">
                <strong>Automatiserade processer:</strong> Du slipper krångliga formulär och långa väntetider. Vår teknik gör allt åt dig.
              </Typography>
            </Box>
            <Box component="li" sx={{ marginBottom: '10px' }}>
              <Typography variant="body2">
                <strong>Enkel tillgång:</strong> Håll koll på dina tidigare krav och betalningar på ett ställe.
              </Typography>
            </Box>
            <Box component="li" sx={{ marginBottom: '10px' }}>
              <Typography variant="body2">
                <strong>Förlorad data:</strong> Om du tar bort ditt konto raderas all din historik och du kommer inte kunna återfå den.
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Typography variant="body1" gutterBottom>
          Är du säker på att du vill gå vidare? Denna åtgärd är permanent och kan inte ångras.
        </Typography>

        <Space type={"xlarge"} />
        <Button variant="contained" color="error" onClick={handleOpen} sx={{ width: "200px" }}>
          Radera konto
        </Button>

        {/* Confirmation Dialog */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Bekräfta borttagning</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Är du säker på att du vill radera ditt konto? Denna åtgärd är permanent och du förlorar all din data och dina förmåner.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Avbryt
            </Button>
            <Button onClick={handleDelete} color="error" variant="contained">
              Radera
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};


export const AccountSettings: React.FC<{}> = ({}): JSX.Element => {
  const classes = useStyles();

  const questions = [
    // TODO: Add email change functionality
    // {
    //   title: "Mejl",
    //   content: "I förskott, ingenting. Klimra arbetar på en no-win no-fee-basis och det finns därför inga kostnader för dig om ditt krav inte lyckas. Om vi framgångsrikt driver din kompensation är vårt standardförfarande att tågbolaget överför pengarna direkt till dig, och därefter debiterar vi vår framgångsavgift på 20% på ditt betalkort.",
    //   subtitle: "Lägg till eller byt mejladress",
    //   icon: faEnvelope,
    // },
    {
      title: "Konto",
      subtitle: "Ändra ditt konto",
      icon: faUserCircle,
      content: <AccountModifyAdditionalDetails />,
    },
    {
      title: "Logga ut",
      subtitle: "Logga ut från ditt konto",
      icon: faSignOutAlt,
      content: <AccountLogout />,
    },
    {
      title: "Radera konto",
      subtitle: "Radera ditt Klimra konto",
      icon: faTrashAlt,
      content: <AccountDelete />,
    }
  ];

  return (
    <div className={classes.faqContainer}>
      <h1>Kontoinställningar</h1>
      <div className={classes.questionsContainer}>
        {questions.map((question, index) => (
          <ClickableAccordion
            key={index}
            text={question.title}
            icon={question.icon}  
            subText={question.subtitle}
          >
            {question.content}
          </ClickableAccordion>
        ))}
      </div>
    </div>
  );
};
