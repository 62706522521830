import { faHandshakeAngle, faXmark} from "@fortawesome/free-solid-svg-icons";
import { BannerFooter } from "Components/Common/BannerFooter";
import React from "react";
import { createUseStyles, Theme } from "react-jss";
import KlimraModal from "Components/Modals/KlimraModal";
import CompensationForm from "Components/Modals/CompensationForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bannerVideo from "assets/videos/banner.mp4";

const useStyles = createUseStyles((theme: Theme) => ({
  bannerContainer: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.textSecondary,
    textAlign: "center",
    padding: "20px",
    boxSizing: "border-box",
    overflow: "hidden",
    "@media (max-width: 670px)": {
      padding: "5px",
    },
  },
  videoBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  tintOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 0,
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "90%",
    marginBottom: "4rem",
    zIndex: 1,
    "@media (max-width: 670px)": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  bannerText: {
    fontSize: "3.75rem",
    fontWeight: "bold",
    marginTop: "75px",
    marginBottom: "0.5rem",
    width: "100%",
    "@media (max-width: 670px)": {
      fontSize: "10vw",
    },
  },
  bannerSubText: {
    fontSize: "1.5rem",
    marginBottom: "1rem",
    padding: "0 20px",
    "@media (max-width: 670px)": {
      fontSize: "5vw",
      padding: "0",
    },
  },
  buttonWrapper: {
    marginTop: "2rem",
  },
  button: {
    padding: "1rem 2.5rem",
    margin: "0 auto",
    fontSize: "1rem",
    fontWeight: "bolder",
    textDecoration: "none",
    color: theme.colors.textPrimary,
    backgroundColor: theme.colors.primary,
    border: "none",
    borderRadius: "9999px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      backgroundColor: theme.colors.buttonHover,
    },
    "@media (max-width: 400px)": {
      padding: "1rem 1.6rem",
      fontSize: "0.8rem",
      width: "auto",
    },
  },
  closeButton: {
    extend: "button",
    backgroundColor: theme.colors.backgroundGray,
    marginTop: "1rem",
  },
  closeButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  closeButtonIcon: {
    marginLeft: "1rem",
    display: "flex",
    alignItems: "center",
  }
}));

interface Props {}

export const MainBannerSection: React.FC<Props> = (): JSX.Element => {
  const classes = useStyles();

  const ModalButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    const classes = useStyles();
    return (
      <button className={classes.button} onClick={onClick}>
        Är jag berättigad till kompensation?
      </button>
    );
  };

  const CloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
      <button className={classes.closeButton} onClick={onClick}>
        <div className={classes.closeButtonContainer}>
          Avbryt
          <FontAwesomeIcon icon={faXmark} className={classes.closeButtonIcon} />
        </div>
      </button>
    );
  }

  return (
    <div className={classes.bannerContainer}>
      <video className={classes.videoBackground} src={bannerVideo} autoPlay loop muted/>
      <div className={classes.tintOverlay}></div>
      <div className={classes.content}>
        <h1 className={classes.bannerText}>Pengar tillbaka för dina försenade tåg</h1>
        <p className={classes.bannerSubText}>
          I år är det 3,85M+ förseningsminuter för passagerartågen i Sverige
        </p>
        <div className={classes.buttonWrapper}>
          <KlimraModal CloseButtonComponent={CloseButton} ButtonComponent={ModalButton} children={<CompensationForm/> } />
        </div>
      </div>
      <BannerFooter
        icon={faHandshakeAngle}
        text={"I samarbete med SSE Business Lab"}
    />
    </div>
  );
};
