import { all, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'app/store';
import { DownloadTicketResponse, GetTicketsResponse, UploadTicketResponse } from 'types/Response';
import { contactSupportAPI, deleteTicketAPI, downloadTicketAPI, getTicketsAPI, loadingTicketAPI, uploadTicketAPI } from './TicketsAPI';
import { setBooleanMapping, setLoading, setLoadingMultiple } from 'features/app/AppSlice';
import { setContactSupport, setDeleteTicket, setInvalidTickets, setLoadingTicket, setTickets } from './TicketsSlice';
import { remapAndMergeTickets } from 'helpers/remappers';
import { PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';


function* getTickets() {
  try {
    yield put(setLoading({ key: 'getTickets', isLoading: true }));

    const token: string = yield select((state: RootState) => state.user.userDetails.token);
    const ticketsObj: GetTicketsResponse = yield getTicketsAPI(token);

    yield put(setTickets(remapAndMergeTickets(ticketsObj)));
    yield put(setLoadingTicket(ticketsObj.loadingTicket));
    // yield put(setInvalidTickets(ticketsObj.invalidTickets.map(ticket => ticket.uploadTime)));

    yield put(setInvalidTickets(["repsac77777@gmail.com/asdasdasd.pdf"]));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setLoading({ key: 'getTickets', isLoading: false }));
  }
}

function* deleteTicket(payload: PayloadAction<{uploadTime: string}[]>) {
  try {
    yield put(setLoading({ key: `deleteTicket#${payload.payload[0].uploadTime}`, isLoading: true }));

    const token: string = yield select((state: RootState) => state.user.userDetails.token);

    yield deleteTicketAPI(token, payload.payload);
    toast.success('Biljett lyckades raderas');

    yield put(setDeleteTicket(payload.payload));
  } catch (error) {
    console.error(error);
    toast.error('Något gick fel, försök igen senare');
  } finally {
    yield put(setLoading({ key: `deleteTicket#${payload.payload[0].uploadTime}`, isLoading: false }));
  }
}

function* contactSupport(payload: PayloadAction<{message: string, ticketNumber: string}>) {
  try {
    yield put(setLoading({ key: `supportTicket#${payload.payload.ticketNumber}`, isLoading: false }));

    const token: string = yield select((state: RootState) => state.user.userDetails.token);

    yield contactSupportAPI(token, payload.payload);
    toast.success('Meddelande skickat');

    yield put(setContactSupport(payload.payload));

  } catch (error) {
    console.error(error);
    toast.error('Något gick fel, försök igen senare');
  } finally {
    yield put(setLoading({ key: `supportTicket#${payload.payload.ticketNumber}`, isLoading: false }));
  }
}

function* downloadTicket(payload: PayloadAction<{ s3Path: string}>) {
  try {
    yield put(setLoading({ key: `downloadTicket#${payload.payload.s3Path}`, isLoading: true }));

    const token: string = yield select((state: RootState) => state.user.userDetails.token);
    const ticketUrl: DownloadTicketResponse = yield downloadTicketAPI(token, payload.payload.s3Path);
    console.log(ticketUrl);
    window.open(ticketUrl.url, '_blank');
  } catch (error) {
    console.error(error);
    toast.error('Något gick fel, försök igen senare');
  } finally {
    yield put(setLoading({ key: `downloadTicket#${payload.payload.s3Path}`, isLoading: false }));
  }
}

async function uploadFileToPresignedUrl(file: File, url: string) {
  const response = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': 'application/pdf',
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to upload file: ${file.name}`);
  }

  return response;
}

function* uploadTicket(payload: PayloadAction<{ files: File[] }>) {
  const loadingKeys = payload.payload.files.map(file => `fileUpload#${file.name}`);

  try {
    yield put(setLoadingMultiple({ keys: loadingKeys, isLoading: true }));

    const token: string = yield select((state: RootState) => state.user.userDetails.token);
    const urls: UploadTicketResponse = yield uploadTicketAPI(token, {files: payload.payload.files.map(file => ({ name: file.name }))});

    yield put({ type: LOADING_TICKET, payload: urls.urls.map(({ fileId }) => ({ fileName: `${fileId}.pdf` })) });
    const fileMap = new Map(payload.payload.files.map(file => [file.name, file]));

    const uploadTasks = urls.urls.map(({ url, name }) => {
      const file = fileMap.get(name);
      if (!file) {
        throw new Error(`File with name ${name} not found`);
      }
      return uploadFileToPresignedUrl(file, url);
    });

    yield Promise.all(uploadTasks);

    toast.success('Biljetterna uppladdade');
    yield put(setLoadingTicket(true));
    yield put(setBooleanMapping({ key: "showUploadPopup", value: false }));

  } catch (error) {
    console.error(error);
    toast.error('Något gick fel, försök igen senare');
  } finally {
    yield put(setLoadingMultiple({ keys: loadingKeys, isLoading: false }));
  }
}

function* loadingTicket(payload: PayloadAction<{ fileName: string}[]>) {
  try {
    const token: string = yield select((state: RootState) => state.user.userDetails.token);
    yield loadingTicketAPI(token, payload.payload);
  } catch (error) {
    console.error(error);
  }
}

export default function* TicketsSaga() {
  yield all([
    takeLatest(GET_TICKETS, getTickets),
    takeLatest(DELETE_TICKET, deleteTicket),
    takeLatest(CONTACT_SUPPORT_TICKET, contactSupport),
    takeLatest(DOWNLOAD_TICKET, downloadTicket),
    takeLatest(UPLOAD_TICKET, uploadTicket),
    takeLatest(LOADING_TICKET, loadingTicket),
  ]);
}

export const GET_TICKETS = 'tickets/GET_TICKETS';
export const DELETE_TICKET = 'tickets/DELETE_TICKET';
export const CONTACT_SUPPORT_TICKET = 'tickets/CONTACT_SUPPORT';
export const DOWNLOAD_TICKET = 'tickets/DOWNLOAD_TICKET';
export const UPLOAD_TICKET = 'tickets/UPLOAD_TICKET';
export const LOADING_TICKET = 'tickets/LOADING_TICKET';
