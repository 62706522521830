import { useAppDispatch } from "app/hooks";
import { GOOGLE_LOGOUT_REQUEST } from "features/user/UserSaga";
import { useEffect } from "react";

export const LogOut: React.FC<{ }> = ({ }): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({type: GOOGLE_LOGOUT_REQUEST });
  }, [dispatch]);

  return <></>;
}