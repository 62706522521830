import AppSaga from 'features/app/AppSaga';
import TicketsSaga from 'features/tickets/TicketsSaga';
import UserSaga from 'features/user/UserSaga';
import { all, fork } from 'redux-saga/effects';


export default function* rootSaga() {
  yield all([
    fork(UserSaga),
    fork(AppSaga),
    fork(TicketsSaga),
  ]);
}
