import React, { useEffect } from 'react';
import { createUseStyles, Theme } from 'react-jss';

const useStyles = createUseStyles((_theme: Theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    pointerEvents: 'none',
  },
  dotSpinner: {
    '--uib-size': '6rem',
    '--uib-speed': '.9s',
    '--uib-color': '#ffff',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'var(--uib-size)',
    width: 'var(--uib-size)',
  },
  dot: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    '&::before': {
      content: '""',
      height: '20%',
      width: '20%',
      borderRadius: '50%',
      backgroundColor: 'var(--uib-color)',
      transform: 'scale(0)',
      opacity: 0.5,
      animation: '$pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite',
      boxShadow: '0 0 20px rgba(18, 31, 53, 0.3)',
    },
  },
  dot2: { transform: 'rotate(45deg)', '&::before': { animationDelay: 'calc(var(--uib-speed) * -0.875)' } },
  dot3: { transform: 'rotate(90deg)', '&::before': { animationDelay: 'calc(var(--uib-speed) * -0.75)' } },
  dot4: { transform: 'rotate(135deg)', '&::before': { animationDelay: 'calc(var(--uib-speed) * -0.625)' } },
  dot5: { transform: 'rotate(180deg)', '&::before': { animationDelay: 'calc(var(--uib-speed) * -0.5)' } },
  dot6: { transform: 'rotate(225deg)', '&::before': { animationDelay: 'calc(var(--uib-speed) * -0.375)' } },
  dot7: { transform: 'rotate(270deg)', '&::before': { animationDelay: 'calc(var(--uib-speed) * -0.25)' } },
  dot8: { transform: 'rotate(315deg)', '&::before': { animationDelay: 'calc(var(--uib-speed) * -0.125)' } },

  '@keyframes pulse0112': {
    '0%, 100%': { transform: 'scale(0)', opacity: 0.5 },
    '50%': { transform: 'scale(1)', opacity: 1 },
  },
}));

interface Props {
  isLoading: boolean;
}

export const LoadingOverlay: React.FC<Props> = ({ isLoading }): JSX.Element => {
  const classes = useStyles();

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  if (!isLoading) return <></>;

  return (
    <div className={classes.overlay}>
      <div className={classes.dotSpinner}>
        <div className={`${classes.dot} ${classes.dot2}`} />
        <div className={`${classes.dot} ${classes.dot3}`} />
        <div className={`${classes.dot} ${classes.dot4}`} />
        <div className={`${classes.dot} ${classes.dot5}`} />
        <div className={`${classes.dot} ${classes.dot6}`} />
        <div className={`${classes.dot} ${classes.dot7}`} />
        <div className={`${classes.dot} ${classes.dot8}`} />
      </div>
    </div>
  );
};
