export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FIRST_TIME: "/first-time",
  MY_RIGHTS: "/my-rights",
  MY_TRIPS: "/my-trips",
  ABOUT_US: "/about-us",
  FAQ: "/faq",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICE: "/terms-of-service",
  COOKIES: "/cookies",
  NOT_FOUND: "*",
  SETTINGS: "/settings",
  INVESTOR: "/investor",
  AUTH_CALLBACK: "/auth/callback",
  AUTH_LOGOUT: "/auth/logout",
  USER_ADDITIONAL: "/account/additional-details",
};

export const ROUTES_AUTHENTICATED = [
  ROUTES.MY_TRIPS,
  ROUTES.SETTINGS,
  ROUTES.USER_ADDITIONAL
];
