import { InformationBox } from "Components/Common/InformationBox";
import { InfoSlideWithImage } from "Components/Common/InfoSlideWithImage";
import { createUseStyles, Theme } from "react-jss";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { ROUTES } from "constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faUpload } from "@fortawesome/free-solid-svg-icons";
import { TripCard } from "./TripCard";
import PersonOnTrain from "assets/images/faq/faq-banner.jpg";
import Button from '@mui/material/Button';
import { UploadPopup } from "Components/Common/UploadPopup";
import { KlimraTheme } from "styles/theme";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { TripCardSkeleton } from "./TripCardSkeleton";
import { Ticket } from "types/Trip";
import { setBooleanMapping } from "features/app/AppSlice";


const useStyles = createUseStyles((theme: Theme) => ({
  tripsContainer: {
    backgroundColor: theme.colors.buttonPrimary,
    padding: "2rem 20vmin",
    "@media (max-width: 1700px)": {
      padding: "2rem 10vmin",
    },
    "@media (max-width: 1300px)": {
      padding: "2rem",
    },
    "@media (max-width: 700px)": {
      padding: "1rem 0.5rem",
    },
    "& > div": {
      paddingBlock: "2rem",
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
  },
  headerWithButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: "-1.5rem",
    "@media (max-width: 400px)": {
      flexDirection: 'column',
      textAlign: 'center',
      gap: '0rem',
      "& > *": {
        width: '100%',
      },
      "& > *:last-child": {
        marginBottom: '1rem',
      },
    }
  },
}));

interface Props {}

export const MyTripsSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const tickets = useAppSelector((state) => state.tickets.tickets);
  const isLoading = useAppSelector((state) => state.app.loading["getTickets"]);
  const specificLoading = useAppSelector((state) => state.app.loading);
  const loadingTicketUpload = useAppSelector((state) => state.tickets.loadingTicket);
  const showUploadPopup = useAppSelector((state) => state.app.booleanMapping["showUploadPopup"]);

  const handleUploadClick = () => {
    dispatch(setBooleanMapping({ key: "showUploadPopup", value: true }));
  };

  const handleClosePopup = () => {
    dispatch(setBooleanMapping({ key: "showUploadPopup", value: false }));
  };


  if (tickets.length == 0 && !isLoading && !loadingTicketUpload) {
    return (
      <>
        {showUploadPopup && <UploadPopup onClose={handleClosePopup} />}
        <InformationBox
          header="Mina resor"
          subHeader="Här kommer dina tågresor att dyka upp. Vanligtvis tar det oss en timme att granska resorna för att avgöra om du är berättigad kompensation eller inte. Skulle din resa inte dyka upp kan du kontakta oss."
        >
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faUpload} />}
            onClick={handleUploadClick}
            sx={{ backgroundColor: KlimraTheme.colors.secondary, marginTop: '1rem' }}
            size="large"
          >
            Ladda Upp Biljett
          </Button>
        </InformationBox>
        <InfoSlideWithImage
          title="Hur Klimra förbättrar din reseupplevelse:"
          imageSrc={PersonOnTrain}
          imageAlt="Person on train looking down at phone"
        >
          <ul>
            <li>Vi övervakar din resa, så du slipper.</li>
            <li>
              Vi ger dig realtidsuppdateringar för att hålla dig informerad.
            </li>
            <li>Vi hanterar alla ersättningskrav åt dig.</li>
            <li>
              Vi säkerställer en stressfri resa med smidig support i varje steg.
            </li>
          </ul>
          <AnchorButton path={ROUTES.REGISTER}>
            Kräv kompensation <FontAwesomeIcon icon={faArrowRight} />
          </AnchorButton>
        </InfoSlideWithImage>
      </>
    );
  }

  const upcomingTrips = tickets.filter((ticket) => {
    const tripDate = new Date(ticket.departureTime);
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    return tripDate > nextDay;
  })

  const previousTrips = tickets.filter((ticket) => {
    const tripDate = new Date(ticket.departureTime);
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    return tripDate < nextDay;
  });

  const todaysTrips = tickets.filter((ticket) => {
    const tripDate = new Date(ticket.departureTime);
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    return tripDate < nextDay && tripDate > new Date();
  });

  const statementLoadingChecker = (ticket: Ticket) => {
    if(ticket.trip.some(t => `deleteTicket#${t.uploadTime}` in specificLoading && specificLoading[`deleteTicket#${t.uploadTime}`])) {
      return true;
    } else if (`supportTicket#${ticket.ticketNumber}` in specificLoading && specificLoading[`supportTicket#${ticket.ticketNumber}`]) {
      return true;
    } else if (`downloadTicket#${ticket.trip[0].s3TicketPath}` in specificLoading && specificLoading[`downloadTicket#${ticket.trip[0].s3TicketPath}`]) {
      return true;
    } else {
      return false;
    }
  }

  const settings = () => {
    return (
      <div>
        {/* <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          sx={{ backgroundColor: KlimraTheme.colors.secondary, marginRight: '1rem' }}
        >
          Sök efter biljetter
        </Button> */}
        <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faUpload} />}
          onClick={handleUploadClick}
          sx={{ backgroundColor: KlimraTheme.colors.secondary }}
        >
          Ladda Upp Biljett
        </Button>
      </div>
    )
  }

  return (
    <div className={classes.tripsContainer}>
      {showUploadPopup && <UploadPopup onClose={handleClosePopup} />}
      <center>
        <h1>Mina resor</h1>
      </center>
      {loadingTicketUpload &&
        <>
        <div>
          <div className={classes.headerWithButton}>
            <h2>Biljetten Laddas Upp...</h2>
            {settings()}
          </div>
            <TripCardSkeleton />
        </div>
        </>
      }
      {isLoading && !specificLoading && (
        <>
          <div>
            <div className={classes.headerWithButton}>
              <h2></h2>
            </div>
              <TripCardSkeleton />
              <TripCardSkeleton />
          </div>
        </>
      )}
      {(!isLoading || loadingTicketUpload) && (
        <>
        {todaysTrips.length > 0 && (
          <div>
            <div className={classes.headerWithButton}>
              <h2>Dagens Resor</h2>
              {!loadingTicketUpload ? settings() : null}
            </div>
            {todaysTrips.map((trip, index) => {
              if (statementLoadingChecker(trip)) {
                return <TripCardSkeleton key={index} />
              } else {
                return <TripCard ticket={trip} ticketType="upcoming" key={index} />
              }
            })}
          </div>
        )}
        {upcomingTrips.length > 0 && (
          <div>
            <div className={classes.headerWithButton}>
              <h2>Kommande resor</h2>
              {!loadingTicketUpload ? settings() : null}
            </div>
            {upcomingTrips.map((trip, index) => {
              if (statementLoadingChecker(trip)) {
                return <TripCardSkeleton key={index} />
              } else {
                return <TripCard ticket={trip} ticketType="upcoming" key={index} />
              }
            })}
          </div>
        )}
        {previousTrips.length > 0 && (
          <div>
            <div className={classes.headerWithButton}>
              <h2>Tidigare Resor</h2>
              {upcomingTrips.length == 0 && !loadingTicketUpload && settings()}
            </div>
            {previousTrips.map((trip, index) => {
              if (statementLoadingChecker(trip)) {
                return <TripCardSkeleton key={index} />
              } else {
                return <TripCard ticket={trip} ticketType="upcoming" key={index} />
              }
            })}
          </div>
        )}
        </>
      )}
    </div>
  );
};
