import React, { useState } from "react";
import { createUseStyles, Theme } from "react-jss";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useAppSelector } from "app/hooks";

const useStyles = createUseStyles((theme: Theme) => ({
  hamburgerMenuContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '50px',
    cursor: 'pointer',
    padding: '10px',
    width: 'fit-content',
    marginLeft: 'auto',
  },
  hamburgerIconWrapper: {
    position: 'relative',
    width: '50px',
    height: '50px',
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    marginTop: '22px',
    marginRight: '25px',
    cursor: 'pointer',
    '&:hover': {
      '& $hamburgerIcon': {
        backgroundColor: theme.colors.secondary,
      },
    },
    '@media (max-width: 450px)': {
      marginTop: '18px',
    },
    '@media (max-width: 380px)': {
      marginTop: '15px',
    },
  },
  hamburgerIconLines: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '24px',
    cursor: 'pointer',
  },
  hamburgerIcon: {
    width: '35px',
    height: '4px',
    backgroundColor: theme.colors.textPrimary,
    transition: '0.4s',
    cursor: 'pointer',
  },
  menuContainer: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '250px',
    height: '100vh',
    backgroundColor: theme.colors.backgroundPrimary,
    boxShadow: (props: any) => props.menuOpen ? '-4px 0px 8px rgba(0, 0, 0, 0.3)' : 'none',
    transform: 'translateX(100%)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  menuContainerOpen: {
    transform: 'translateX(0)',
  },
  closeIcon: {
    alignSelf: 'flex-start',
    fontSize: '24px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  menuItem: {
    padding: '15px 0',
    color: theme.colors.textPrimary,
    textDecoration: 'none',
    fontSize: '22px',
    '&:hover': {
      color: theme.colors.secondary,
    },
  },
  linkWrapper: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease, visibility 0.3s ease',
    zIndex: 999,
  },
  overlayVisible: {
    opacity: 1,
    visibility: 'visible',
  },
  contactButton: {
    backgroundColor: theme.colors.buttonPrimary,
    borderRadius: '20px',
    padding: '16px 28px',
    fontSize: '18px',
    color: theme.colors.textPrimary,
    border: 'none',
    cursor: 'pointer',
    width: 'fit-content',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: theme.colors.buttonHover,
    },
  }
}));

export const HamburgerMenu: React.FC<{}> = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles({ menuOpen });

  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div className={classes.hamburgerMenuContainer} onClick={toggleMenu}>
        <div className={classes.hamburgerIconWrapper}>
          <div className={classes.hamburgerIconLines}>
            <div className={classes.hamburgerIcon}></div>
            <div className={classes.hamburgerIcon}></div>
            <div className={classes.hamburgerIcon}></div>
          </div>
        </div>
      </div>
      <div
        className={`${classes.overlay} ${menuOpen ? classes.overlayVisible : ''}`}
        onClick={closeMenu}
      />
      <div className={`${classes.menuContainer} ${menuOpen ? classes.menuContainerOpen : ''}`}>
        <span className={classes.closeIcon} onClick={closeMenu}>✕</span>
        <div className={classes.linkWrapper}>
          {isAuthenticated ? (
            <>
              <Link to={ROUTES.MY_TRIPS} className={classes.menuItem} onClick={closeMenu}>Mina resor</Link>
              <Link to={ROUTES.SETTINGS} className={classes.menuItem} onClick={closeMenu}>Inställningar</Link>
            </>
          ) : (
            <>
              <Link to={ROUTES.LOGIN} className={classes.menuItem} onClick={closeMenu}>Logga in</Link>
              <Link to={ROUTES.REGISTER} className={classes.menuItem} onClick={closeMenu}>Skapa Konto</Link>
            </>
          )}
          <Link to={ROUTES.MY_RIGHTS} className={classes.menuItem} onClick={closeMenu}>Dina rättigheter</Link>
          <Link to={ROUTES.ABOUT_US} className={classes.menuItem} onClick={closeMenu}>Om oss</Link>
          <Link to={ROUTES.FAQ} className={classes.menuItem} onClick={closeMenu}>FAQ</Link>
        </div>
        <button className={classes.contactButton} onClick={() => { navigate(ROUTES.ABOUT_US); closeMenu() }}>Kontakta oss</button>
      </div>
    </>
  );
};
