import { requestApi } from "api";

export const getTicketsAPI = async (token: string) => {
  return await requestApi(`tickets`, "GET", token);
};

export const deleteTicketAPI = async (token: string, items: {uploadTime: string}[]) => {
  return await requestApi(`tickets`, "DELETE", token, { items });
}

export const applyCompensationAPI = async (token: string, body: {email: string, ticketNumber: string, s3Path: string, message: string}) => {
  return await requestApi(`tickets/compensation`, "POST", token, body);
}

export const contactSupportAPI = async (token: string, body: {ticketNumber: string, message: string}) => {
  return await requestApi(`tickets/support`, "POST", token, body);
}

export const downloadTicketAPI = async (token: string, s3Path: string) => {
  return await requestApi(`tickets/download`, "POST", token, { s3Path });
}

export const uploadTicketAPI = async (token: string, files: {files: {name: string}[]}) => {
  return await requestApi(`tickets/upload`, "POST", token, files);
}

export const loadingTicketAPI = async (token: string, items: {fileName: string}[]) => {
  return await requestApi(`tickets/loading`, "POST", token, { items });
}
