export let apiEndpoint = process.env.REACT_APP_API_URL;

export const requestApi = async (path: string, method: "GET" | "POST" | "PUT" | "DELETE", token: string, data: any = null) => {
  if (!path.startsWith("/")) path = `/${path}`;

  let url = `${apiEndpoint}${path}`;
  let headers: { [key: string]: string } = { 'Content-Type': 'application/json' };

  if (token) headers["Authorization"] = `Bearer ${token}`;

  const response = await fetch(url, {
    method: method,
    headers,
    body: data && method.toUpperCase() !== "GET" ? JSON.stringify(data) : null,
  });

  try {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(JSON.stringify(response));
    }
  } catch (error) {
    console.error(error);
  }

  return await response.json();
};
