import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { Banner } from "Components/Common/Banner";
import { ROUTES } from "constants/routes";
import { useStylesLogin } from "pages/login/Login";
import { useNavigate } from "react-router-dom";
import banner from "assets/images/login/register-login-banner.jpg";
import googleIcon from "assets/images/icons/googleIcon.png";
import { GOOGLE_LOGIN_REQUEST } from "features/user/UserSaga";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Box, Button } from "@mui/material";
import { LoadingIcon } from "Components/Misc/LoadingIcon";
import { KlimraTheme } from "styles/theme";


export const Register: React.FC<{ }> = ({  }): JSX.Element => {
	const loginClasses = useStylesLogin();
	const navigate = useNavigate();
	const dispatch = useAppDispatch()

	const isLoading = useAppSelector((state) => state.app.loading["googleLogin"]);
	
	return (
		<div>
			<Banner
				title="Få full kontroll över dina tågresor"
				subTitle="Skappa ett konto för enkelt hantera alla dina tågresor. Följ
						dina resor smidigt och ansök snabbt om att få ersättning vid förseningar.
						Vi finns här för att göra din reseupplevelse enklare och stressfri.
						"
				bannerFooter={
					{
						icon: faLightbulb,
						text: "Din resa, vår prioritet",
					}
				}
				image={banner}
			>
			<div className={loginClasses.container}>
				<h1 className={loginClasses.title}>Skapa ett konto</h1>
				<p className={loginClasses.description}>Säg upp kontot när du vill</p>
				<Box mb={2}>
					<Button
						variant="contained"
						onClick={() => dispatch({ type: GOOGLE_LOGIN_REQUEST })}
						sx={{
							backgroundColor: KlimraTheme.colors.buttonPrimary,
							color: KlimraTheme.colors.textPrimary,
							width: '275px',
							height: '50px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						startIcon={isLoading ? null : <img src={googleIcon} alt="Google Logo" style={{ width: '20px' }} />}
					>
						
						{isLoading ? <LoadingIcon isLoading={true} className={loginClasses.loadingIcon} /> : 'Skapa Konto via Google'}
					</Button>
				</Box>
				<p className={loginClasses.createAccountText}>
					Har du redan ett konto?
					<a onClick={() => navigate(ROUTES.LOGIN)}> Logga in</a>
				</p>
			</div>
			</Banner>
		</div>
	);
}
