import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  loading: { [key: string]: boolean };
  initialLoad: boolean;
  error: { [key: string]: string };
  reRoute: string | null;
  booleanMapping: { [key: string]: boolean };
}

const initialState: AppState = {
  loading: {},
  initialLoad: false,
  error: {},
  reRoute: null,
  booleanMapping: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<{ key: string; isLoading: boolean }>) {
      state.loading[action.payload.key] = action.payload.isLoading;
    },
    setLoadingMultiple(state, action: PayloadAction<{ keys: string[]; isLoading: boolean }>) {
      action.payload.keys.forEach((key) => {
        state.loading[key] = action.payload.isLoading;
      });
    },
    setError(state, action: PayloadAction<{ key: string; error: string }>) {
      state.error[action.payload.key] = action.payload.error;
    },
    clearError(state) {
      state.error = {};
    },
    setInitialLoad(state) {
      state.initialLoad = true;
    },
    setReRoute(state, action: PayloadAction<string>) {
      state.reRoute = action.payload;
    },
    setBooleanMapping(state, action: PayloadAction<{ key: string; value: boolean }>) {
      state.booleanMapping[action.payload.key] = action.payload.value;
    }
  },
});

export const { setLoading, setLoadingMultiple, setError, clearError, setInitialLoad, setReRoute, setBooleanMapping } = appSlice.actions;
export default appSlice.reducer;
