import React, { useState } from 'react';
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { Banner } from "Components/Common/Banner";
import { Button, Checkbox, FormControlLabel, TextField, Typography, Box } from '@mui/material';
import { createUseStyles, Theme } from "react-jss";
import banner from "assets/images/login/register-login-banner.jpg";
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setUserAdditional } from 'features/user/UserSlice';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { KlimraTheme } from 'styles/theme';
import { LoadingIcon } from 'Components/Misc/LoadingIcon';
import { ADD_USER_ADDITIONAL } from 'features/user/UserSaga';

export const useStylesAdditionalDetails = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '70px 100px',
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    width: '60vw',
    maxWidth: '400px',
    margin: '30px 0 200px 0',
    "@media (max-width: 900px)": {
      width: '85%',
      padding: '40px 20px',
    },
    "@media (max-width: 550px)": {
      width: '90%',
      padding: '40px 20px',
    },
    "@media (max-width: 400px)": {
      padding: '20px 10px',
    },
  },
  leftAligned: {
    textAlign: 'left',
    width: '100%',
  },
  smallText: {
    fontSize: '0.700rem !important',
    lineHeight: '1',
  },
  smallLink: {
    fontSize: '0.700rem !important',
    lineHeight: '1',
    marginTop: '0px',
  },
  loadingIcon: {
    marginBottom: '-10px',
    marginTop: '-5px',
    transform: 'scale(0.8)',
  }
}));

export const AdditionalDetails: React.FC = (): JSX.Element => {
  const classes = useStylesAdditionalDetails();
  const dispatch = useAppDispatch();

  const userAdditional = useAppSelector((state) => state.user.userAdditional);
  const isLoading = useAppSelector((state) => state.app.loading["addUserAdditional"]);

  const [errors, setErrors] = useState({
    firstname: false,
    lastname: false,
    phoneNumber: false,
    personalIdentificationNumber: false,
    agreeToTerms: false,
    agreeToPowerOfAttorney: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    dispatch(
      setUserAdditional({
        ...userAdditional,
        [name]: type === 'checkbox' ? checked : value,
      })
    );
  };

  const handleSubmit = () => {
    const newErrors = {
      firstname: !userAdditional.firstname,
      lastname: !userAdditional.lastname,
      phoneNumber: !userAdditional.phoneNumber,
      personalIdentificationNumber: !userAdditional.personalIdentificationNumber,
      agreeToTerms: !userAdditional.agreeToTerms,
      agreeToPowerOfAttorney: !userAdditional.agreeToPowerOfAttorney,
    };

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => !error);

    if (isValid) {
      dispatch({ type: ADD_USER_ADDITIONAL });
    }
  };

  return (
    <div>
      <Banner
        title="Snart klar"
        subTitle="Helt gratis. Säg upp kontot när du vill."
        bannerFooter={{
          icon: faLightbulb,
          text: "Din resa, vår prioritet",
        }}
        image={banner}
      >
        <div className={classes.container}>
          <Typography variant="h4" gutterBottom color={"black"}>
            Snart klar
          </Typography>
          <Typography variant="subtitle1" gutterBottom color={"black"}>
            Helt Gratis. Säg upp kontot när du vill.
          </Typography>
          {/* Form Fields */}
          <TextField
            label="Förnamn"
            name="firstname"
            value={userAdditional.firstname}
            onChange={handleChange}
            error={errors.firstname}
            helperText={errors.firstname ? 'Förnamn är obligatoriskt' : ''}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Efternamn"
            name="lastname"
            value={userAdditional.lastname}
            onChange={handleChange}
            error={errors.lastname}
            helperText={errors.lastname ? 'Efternamn är obligatoriskt' : ''}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Telefonnummer"
            name="phoneNumber"
            value={userAdditional.phoneNumber}
            onChange={handleChange}
            error={errors.phoneNumber}
            helperText={errors.phoneNumber ? 'Telefonnummer är obligatoriskt' : ''}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Personnummer"
            name="personalIdentificationNumber"
            value={userAdditional.personalIdentificationNumber}
            onChange={handleChange}
            error={errors.personalIdentificationNumber}
            helperText={errors.personalIdentificationNumber ? 'Personnummer är obligatoriskt' : ''}
            fullWidth
            margin="normal"
          />

          {/* Agree to Terms */}
          <Box className={classes.leftAligned} mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name="agreeToTerms"
                  checked={userAdditional.agreeToTerms}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label={
                <Box className={classes.leftAligned}>
                  <Typography variant="body2" className={classes.smallText} color={"black"}>
                    Jag har läst igenom och godkänner användarvillkoren
                  </Typography>
                  <Typography variant="body2" className={classes.smallLink}>
                    <Link to={ROUTES.TERMS_OF_SERVICE}>Läs mer</Link>
                  </Typography>
                </Box>
              }
            />
            {errors.agreeToTerms && (
              <>
              <br />
              <Typography color="error" variant="caption">Du måste godkänna användarvillkoren</Typography>
              </>
            )}
          </Box>

          {/* Agree to Power of Attorney */}
          <Box className={classes.leftAligned} mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name="agreeToPowerOfAttorney"
                  checked={userAdditional.agreeToPowerOfAttorney}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label={
                <Box className={classes.leftAligned}>
                  <Typography variant="body2" className={classes.smallText} color={"black"}>
                    Jag ger Klimra fullmakt att driva mina kompensationsärenden
                  </Typography>
                  <Typography variant="body2" className={classes.smallLink}>
                    <Link to="/power-of-attorney">Läs mer</Link>
                  </Typography>
                </Box>
              }
            />
            {errors.agreeToPowerOfAttorney && (
              <>
              <br />
              <Typography color="error" variant="caption">Du måste godkänna fullmakten</Typography>
              </>
            )}
          </Box>

          <Box mt={2}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: KlimraTheme.colors.buttonPrimary,
                color: KlimraTheme.colors.textPrimary,
                width: '150px',
              }}
            >
              {isLoading ? <LoadingIcon isLoading={true} className={classes.loadingIcon} /> : 'Fortsätt'}
            </Button>
          </Box>
        </div>
      </Banner>
    </div>
  );
};
