import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RedirectToExternal } from 'Components/Common/RedirectExternal';
import { LoadingOverlay } from 'Components/Misc/LoadingOverlay';
import { ROUTES } from 'constants/routes';
import { GET_USER } from 'features/user/UserSaga';
import { AboutUs } from 'pages/about-us/AboutUs';
import { AdditionalDetails } from 'pages/additional-details/AdditionalDetails';
import { AuthCallback } from 'pages/auth-callback/AuthCallback';
import { CookieConsentPopup } from 'pages/cookies/CookieConsentPopup';
import { Cookies } from 'pages/cookies/Cookies';
import { Faq } from 'pages/faq/faq';
import { FirstTime } from 'pages/first-time/FirstTime';
import { Footer } from 'pages/footer/Footer';
import { Home } from 'pages/home/Home';
import { Investor } from 'pages/Investor/Investor';
import { Login } from 'pages/login/Login';
import { LogOut } from 'pages/logout/Logout';
import { MyRights } from 'pages/my-rights/MyRights';
import { MyTrips } from 'pages/my-trips/MyTrips';
import { Navigation } from 'pages/navigation/Navigation';
import { NotFound } from 'pages/not-found/NotFound';
import { Privacy } from 'pages/privacy/Privacy';
import { Register } from 'pages/register/Register';
import { Settings } from 'pages/settings/Settings';
import { UserTerms } from 'pages/user-terms/UserTerms';
import { useEffect, useRef } from 'react';
import { createUseStyles, Theme } from 'react-jss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


const useStyles = createUseStyles((theme: Theme) => ({
  '@global': {
    '@import': "url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap')",
    body: {
      fontFamily: theme.typography.fontFamily,
      margin: 0,
      padding: 0,
      backgroundColor: theme.colors.backgroundPrimary,
      color: theme.colors.textPrimary,
    },
  }
}));

export const App: React.FC<{ }> = ({ }): JSX.Element => {
  useStyles();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.app.loading["authenticating"]);

  const isDispatched = useRef(false);

  useEffect(() => {
    if (!isDispatched.current && location.pathname !== ROUTES.AUTH_CALLBACK) {
      dispatch({ type: GET_USER });
    }
    isDispatched.current = true;
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        <Navigation />
        <CookieConsentPopup />
        <LoadingOverlay isLoading={isLoading} />
        <Routes>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.REGISTER} element={<Register />} />
          <Route path={ROUTES.FIRST_TIME} element={<FirstTime />} />
          <Route path={ROUTES.MY_RIGHTS} element={<MyRights />} />
          <Route path={ROUTES.MY_TRIPS} element={<MyTrips />} />
          <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
          <Route path={ROUTES.FAQ} element={<Faq />} />
          <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
          <Route path={ROUTES.SETTINGS} element={<Settings/>}/>
          <Route path={ROUTES.PRIVACY_POLICY} element={<Privacy/>}/>
          <Route path={ROUTES.TERMS_OF_SERVICE} element={<UserTerms/>}/>
          <Route path={ROUTES.COOKIES} element={<Cookies />}/>
          <Route path={ROUTES.INVESTOR} element={<Investor />} />
          <Route path={ROUTES.AUTH_CALLBACK} element={<AuthCallback />} />
          <Route path={ROUTES.AUTH_LOGOUT} element={<LogOut />} />
          <Route path={ROUTES.USER_ADDITIONAL} element={<AdditionalDetails />} />
          <Route path="/dev" element={<RedirectToExternal url="http://klimra-website-dev.s3-website.eu-north-1.amazonaws.com/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}
