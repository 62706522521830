import { createUseStyles, Theme } from "react-jss";
import { Skeleton } from '@mui/material';


const useStyles = createUseStyles((theme: Theme) => ({
  tripContainer: {
    position: "relative",
    borderRadius: "10px",
    backgroundColor: theme.colors.backgroundPrimary,
    padding: "2rem 2rem",
    filter: "drop-shadow(0 3px 3px #0003)",
    display: "grid",
    gap: "1rem",
    alignItems: "center",
    gridAutoFlow: "column",
    gridTemplateColumns: "200px auto 200px",
    "@media (max-width: 1100px)": {
      display: "flex",
      flexDirection: "column",
    },
    "@media (max-width: 800px)": {
      padding: "2rem 1rem",
    },
    "@media (max-width: 630px)": {
      padding: "2rem 1.5rem",
    },
  },
  chipContainer: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    display: 'flex',
    gap: '10px',
  },
  first: {
    "@media (max-width: 1100px)": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
  tripView: {
    display: "grid",
    gridTemplateColumns: "1fr max(15vw, 200px) 1fr",
    gap: "10px",
    alignItems: "center",
    marginInline: "auto",
    position: "relative",
    fontSize: "1.5rem",
    width: "100%",
    "@media (max-width: 1100px)": {
      gridTemplateColumns: "1fr max(35vw, 200px) 1fr",
      marginBottom: "2rem",
    },
    "@media (max-width: 800px)": {
      transform: "scale(0.8)",
      width: "120%",
      marginInline: "-10%",
    },
    "@media (max-width: 630px)": {
      display: "none",
    },
  },
  tripStatusBar: {
    display: "flex",
    marginInline: "auto",
    alignItems: "center",
    justifyContent: "center",
    width: "max(15vw, 200px)",
    position: "relative",
    "@media (max-width: 1100px)": {
      width: "max(35vw, 200px)",
    },
  },
  stationInfoLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    alignItems: "flex-end",
    textAlign: "right",
    "@media (max-width: 800px)": {
      fontSize: "1.5rem",
    },
  },
  stationInfoRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.5rem",
    marginLeft: "2px",
    "@media (max-width: 800px)": {
      fontSize: "1.5rem",
    },
  },
  horizontalLine: {
    borderBottom: "2px solid black",
    width: "100%",
  },
  timeAboveIconsRight: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginLeft: "-38px",
    marginTop: "-35px",
    width: "40px",
    letterSpacing: "1px",
  },
  timeAboveIconsLeft: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginRight: "-25px",
    marginTop: "-35px",
    width: "40px",
    letterSpacing: "1px",
  },
  paymentStatus: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifySelf: "flex-end",
    fontSize: "1.5rem",
    "@media (max-width: 800px)": {
      marginBottom: "1rem",
    },
  },
  delayBox: {
    gridRow: "2",
    gridColumn: "1 / span all",
  },
  delayStatusInfo: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2rem",
    justifyContent: "space-around",
    "& > div": {
      textAlign: "center",
      "& svg": {
        fontSize: "4rem",
      },
    },
  },
  basicInformationMobile: {
    display: "none",
    "@media (max-width: 800px)": {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  leftItem: {
    justifySelf: "start",
  },
  rightItem: {
    justifySelf: "end",
  },
  tripViewMobile: {
    display: "grid",
    gap: "10px",
    width: "100%",
    gridTemplateRows: "auto auto",
    marginTop: "3rem",
    "@media (max-width: 630px)": {
      gridTemplateColumns: "1fr 1fr",
      textAlign: "center",
    },
    "@media (min-width: 631px)": {
      display: "none",
    },
  },
  tripStatusBarMobile: {
    gridColumn: "1 / span 2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    width: "100%",
  },
  stationInfoLeftMobile: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    "@media (max-width: 630px)": {
      justifyContent: "flex-start",
    },
  },
  stationInfoRightMobile: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "right",
    "@media (max-width: 630px)": {
      justifyContent: "flex-end",
    },
  },
  settingsButton: {
    position: 'absolute',
    top: '4px',
    right: '2px',
  },
  settingsMenu: {
    backgroundColor: theme.colors.backgroundGray,
  },
}));

interface Props {
}

export const TripCardSkeleton: React.FC<Props> = ({ }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.tripContainer}>
      <div className={classes.chipContainer}>
        <Skeleton variant="rectangular" width={200} height={40} />
      </div>
      
      <div className={classes.first}>
        <h2>
          <Skeleton variant="text" width={200} />
        </h2>
        <p>
          <Skeleton variant="text" width={200} />
        </p>
        <p>
          <Skeleton variant="text" width={200} />
        </p>
        <p>
          <Skeleton variant="text" width={200} />
        </p>
      </div>
      
      <div className={classes.basicInformationMobile}>
        <h2 className={classes.leftItem}>
          <Skeleton variant="text" width={200} />
        </h2>
        <p className={classes.rightItem}>
          <Skeleton variant="text" width={200} />
        </p>
        <p className={classes.leftItem}>
          <Skeleton variant="text" width={200} />
        </p>
        <p className={classes.rightItem}>
          <Skeleton variant="text" width={200} />
        </p>
      </div>
      
      <div className={classes.tripView}>
        <div className={classes.stationInfoLeft}>
          <Skeleton variant="text" width={200} />
        </div>

        <div className={classes.tripStatusBar}>
          <p className={classes.timeAboveIconsLeft}><Skeleton variant='text' width={50} /></p>
          <Skeleton variant='rectangular' width={200} />
          <p className={classes.timeAboveIconsRight}><Skeleton variant='text' width={50} /></p>
        </div>

        <div className={classes.stationInfoRight}>
          <strong><Skeleton variant='text' width={200} /></strong>
        </div>
      </div>

      <div className={classes.tripViewMobile}>
        <div className={classes.tripStatusBarMobile}>
          <p className={classes.timeAboveIconsLeft}><Skeleton variant='text' width={50} /></p>
          <Skeleton variant='rectangular' width={200} />
          <p className={classes.timeAboveIconsRight}><Skeleton variant='text' width={50} /></p>
        </div>

        <div className={classes.stationInfoLeftMobile}>
          <strong><Skeleton variant='text' width={200} /></strong>
        </div>

        <div className={classes.stationInfoRightMobile}>
          <strong><Skeleton variant='text' width={200} /></strong>
        </div>

      </div>

      <div className={classes.paymentStatus}>
      <Skeleton variant='text' width={200} />
      </div>
    </div>
  );
};
