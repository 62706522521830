import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  informationSection: {
    padding: "5rem",
    marginInline: "auto",
    width: "70%",
    "& article": {
      marginBlock: "2rem",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "2rem",
    },
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "normal",
    },
    "@media (max-width: 1300px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      padding: "2rem",
      width: "80%",
      "& article": {
        fontSize: "0.9rem",
      },
      "& h1": {
        fontSize: "1.5rem",
      },
      "& h2": {
        fontSize: "1rem",
      },
    },
  },
}));

interface Props {}

export const Policy: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.informationSection}>
      <article>
        <h1 style={{ textAlign: "center" }}>PRIVACY NOTICE for Klimra</h1>
        <h2>Dear User,</h2>
        <p>
          Our company offers a comprehensive service to help customers obtain
          compensation for train delays. We handle the entire compensation
          process, ensuring our customers receive fast and fair compensation
          without the hassle of manual claims.
          <br />
          <br />
          By granting us access to your email, we can confirm which train you
          are on through an algorithm that searches for train tickets. Once we
          verify that the train is delayed, we automatically begin the process
          of requesting compensation on behalf of the customer by sending an
          official email to the train company from our business name. This
          includes preparing and submitting all necessary documentation and
          following up until the compensation has been granted.
          <br />
          <br />
          Our service is designed to be efficient and user-friendly, saving our
          customers time and effort while increasing the likelihood of a
          successful claim. Customers are kept informed at every stage of the
          process, and no action is taken without their explicit approval. We
          operate under the highest standards of data security and privacy,
          ensuring that all personal and travel information is handled securely
          and in compliance with relevant regulations.
          <br />
          <br />
          These services are provided by Klimra AB (https://www.klimra.com),
          organizational number 559490-0986, with a registered address at
          Drömstigen 41.
          <br />
          <br />
          Your privacy is important to us. Under the General Data Protection
          Regulation (2016/679), we are responsible for processing personal data
          where we determine the purposes and means of processing. By "personal
          data," we refer to information that can directly or indirectly be
          linked to a living individual and is processed in connection with our
          Services.
          <br />
          <br />
          This document contains information about our collection, use, and
          processing of personal data, the third parties we may share such data
          with, and your rights concerning personal data about you. When using
          the Services, we will process personal data for several purposes, as
          outlined below.
          <br />
          <br />
          We use third-party providers to help us operate our website and
          deliver our services efficiently. Our website is hosted by Amazon Web
          Services (AWS), a third-party hosting service. This means the files
          and data that make up our website are stored on AWS servers, which
          facilitate the delivery of our web pages to your browser. As such, AWS
          may have access to any data transmitted through our website, including
          personal data.
          <br />
          <br />
          We advise you to read AWS's privacy policy and terms of service to
          understand how they handle your personal data. You can find their
          privacy policy and terms here. Additionally, we use your browser’s
          local storage to keep you logged in with updated access tokens.
        </p>

        <h2>
          <strong>Google API:</strong>
        </h2>
        <p>
          To automate the compensation process, we need access to your email
          inbox. After connecting your email to our application, we store
          "tokens" in a database to later search for tickets in your inbox. In
          some cases, we will also send compensation claims from your email. If
          you wish to disconnect an email address, you can do so by going to My
          Pages and clicking the Email tab. Once you disconnect an email
          address, all "tokens" will be invalidated and deleted from our
          database. If you delete your account, all email connections will also
          be removed and invalidated.
        </p>

        <h2>
          <strong>DATA PROTECTION AND SECURITY</strong>
        </h2>
        <p>
          <strong>Encryption:</strong> All sensitive data transmitted between
          your browser and our servers is encrypted using industry-standard
          Secure Sockets Layer (SSL) technology. This ensures that any
          information sent or received is protected from unauthorized access.
          <br />
          <br />
          <strong>Access Controls:</strong> Access to sensitive data is
          restricted to authorized personnel only. We use robust access control
          mechanisms to ensure that only individuals with legitimate need can
          access your data.
          <br />
          <br />
          <strong>Data Anonymization and Pseudonymization:</strong> Where
          possible, we anonymize or pseudonymize data to further protect your
          privacy. This means data is modified in a way that it cannot be traced
          back to you without additional information.
          <br />
          <br />
          <strong>Data Minimization:</strong> We collect only the minimum amount
          of personal data necessary to provide our services. By limiting the
          data we collect, we reduce the risk of unauthorized access and
          exposure.
          <br />
          <br />
          <strong>Data Retention and Deletion:</strong> We retain personal data
          only as long as necessary to fulfill the purposes for which it was
          collected, or as required by law. When data is no longer needed, we
          ensure it is securely deleted or anonymized.
        </p>

        <h2>
          <strong>PROCESSING PERSONAL DATA:</strong>
        </h2>
        <p>
          We collect and use the following types of personal data to deliver and
          maintain effective and secure services for the purposes outlined
          below:
          <br />
          <br />
          (a) Name;
          <br />
          (b) Email address;
          <br />
          (c) Phone number;
          <br />
          (d) IP address;
          <br />
          (e) Personal identification number.
          <br />
          <br />
          Please note that we cannot provide the Services without processing the
          above-mentioned personal data. When using the Services, certain
          information will be automatically collected, including details about
          your use of our service.
        </p>

        <h2>
          <strong>PURPOSE OF PROCESSING AND LEGAL GROUNDS</strong>
        </h2>
        <p>
          We will process the information outlined above for the following
          purposes:
          <br />
          <br />
          (a) To manage your account, enable the services, and integrate with
          third-party services, as well as to provide the services under the
          terms of use. Processing personal data for this purpose is based on
          the necessity to fulfill our agreement with you.
          <br />
          <br />
          (b) To send you notifications or messages via email or otherwise,
          including marketing of our and our affiliates' products and services,
          based on our legitimate interest in sending you promotional materials
          for products and services that you may be interested in.
          <br />
          <br />
          (c) To inform you about updates to the services or terms of use based
          on our legitimate interest in keeping you informed about the
          development of the services, such as new features and the conditions
          you agreed to.
          <br />
          <br />
          (d) To improve and develop the services or new services and products,
          and to analyze your use of the services, based on our legitimate
          interest in offering you updated services based on your usage, and to
          develop new products and services that meet your preferences, with
          your consent for the use of cookies.
          <br />
          <br />
          (e) To ensure the services function technically and prevent the use of
          the services in violation of the terms of use, to fulfill our
          agreement with you, and based on our legitimate interest in ensuring
          that our services are not misused to protect ourselves and our other
          users.
          <br />
          <br />
          (f) To enforce the terms of use, including protecting our rights,
          property, and safety, as well as third parties' rights, property, and
          safety, if necessary based on our legitimate interest in protecting
          our business, our users of the services, and other third parties.
          <br />
          <br />
          (g) To comply with legal obligations, based on our duty to comply with
          such laws.
        </p>

        <h2>
          <strong>STORAGE OF PERSONAL DATA</strong>
        </h2>
        <p>
          As a general rule, we store your personal data only as long as
          necessary to perform the Services unless there is a legal obligation
          to retain the data longer.
          <br />
          <br />
          After you terminate your account, personal data related to the account
          will be stored for up to 90 days.
          <br />
          <br />
          Personal data processed based on a legal obligation will be retained
          as long as the legal obligation requires. This includes when personal
          data is processed for accounting purposes, where account information
          is stored during the current year and up to seven years after the end
          of the calendar year in which the fiscal year was completed.
        </p>

        <h2>
          <strong>COOKIES, PIXELS, AND OTHER SYSTEM TECHNOLOGIES</strong>
        </h2>
        <p>
          We collect information through technologies like cookies, pixels, and
          local storage (e.g., in your browser or on your device). For
          information on how we use system technology and analytics tools, and
          how you can prevent the use of cookies, please refer to our Cookie
          Notice (available here [https://klimra.com/cookies]).
        </p>

        <h2>
          <strong>RESPONDING TO LEGAL REQUESTS AND PREVENTING HARM</strong>
        </h2>
        <p>
          We may access, preserve, and share your information in response to a
          legal request (such as a search warrant, court order, or subpoena) or
          when necessary to detect, prevent, and address fraud and other illegal
          activities, to protect ourselves, you, and others, including as part
          of investigations, if we believe in good faith that the law requires
          us to do so.
          <br />
          <br />
          This may include responding to legal requests from jurisdictions
          outside the EU/EEA, where we believe in good faith that the response
          is required by law in that jurisdiction, affects users in that
          jurisdiction, and is consistent with internationally recognized
          standards.
          <br />
          <br />
          Information we receive about you through the Website by using our
          Services may be accessed, preserved, and stored for an extended period
          when subject to a legal request or obligation, governmental
          investigation, or investigation of possible violations of our terms or
          policies, or otherwise to prevent harm.
        </p>

        <h2>
          <strong>OTHER THINGS YOU NEED TO KNOW</strong>
        </h2>
        <p>
          <strong>Links to Other Websites</strong>
        </p>
        <p>
          You should be aware that when you are on our website, you may be
          directed to other websites operated by third parties where the
          personal information collected is beyond our control. The privacy
          notice for the new website will govern the information obtained from
          you on that website.
        </p>

        <p>
          <strong>Change of Ownership</strong>
        </p>
        <p>
          If the ownership of our business changes, we may transfer your
          information to the new owners so they can continue providing the
          services. The new owner will still be required to uphold the
          commitments we have made in this privacy notice.
        </p>

        <p>
          <strong>Notice of Changes</strong>
        </p>
        <p>
          If we make changes to this privacy notice, we will notify you by
          email. If the changes are significant, we will provide you with
          additional, prominent notice appropriate to the circumstances, and, if
          required by applicable law, seek your consent.
        </p>

        <h2>
          <strong>Your Privacy Rights Under EU Law</strong>
        </h2>
        <p>
          You have an absolute right to object to the processing of your
          personal data for direct marketing purposes. You also have the right
          to withdraw your previously given consent. The withdrawal of your
          consent does not affect the legality of the processing that was based
          on consent before it was withdrawn, and we may continue to process
          your personal data based on other legal grounds, except for direct
          marketing.
          <br />
          <br />
          You have the right to request access to and further information about
          the processing of your personal data, or to request that we correct,
          rectify, complete, delete, or restrict the processing of your personal
          data. You have the right to receive one (1) free copy of the personal
          data we process about you per calendar year. For any additional copies
          you request, we may charge a reasonable fee based on administrative
          costs.
          <br />
          <br />
          If processing is based on the legal grounds of legitimate or public
          interest, you have the right to object to the processing. If you do
          so, we will no longer be allowed to process the personal data unless
          we can demonstrate compelling reasons that our legitimate grounds for
          processing outweigh your interests, rights, and freedoms—unless the
          processing is necessary to establish, exercise, or defend legal
          claims.
          <br />
          <br />
          If the processing is based on the legal grounds of consent or
          fulfillment of a contract, you have the right to data portability.
          Data portability means you can obtain the personal data you have
          provided to us in a structured, commonly used, and machine-readable
          format and have the right to transfer such data to another data
          controller.
          <br />
          <br />
          To exercise the above rights, or if you have any questions about our
          data-sharing practices, your rights under EU law, or if you would like
          your personal data removed, please contact us at the following
          address: Contact@klimra.com or Klimra AB, Drömstigen 41. To ensure a
          prompt response, please include your full name, and, if applicable,
          your address, username, and the email address used for registration.
          Please note that you must personally sign any request for information
          on the processing of your personal data.
          <br />
          <br />
          If you have any complaints regarding our processing of your personal
          data, you can file a complaint with the competent data protection
          authority. You can learn more about local data protection authorities
          through the following link:
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        </p>

        <h2>
          <strong>Contact Our Data Protection Officer</strong>
        </h2>
        <p>
          If you wish to contact our Data Protection Officer, you can do so at
          Pontus.thybell@gmail.com.
        </p>
      </article>
    </section>
  );
};
