import { useAppDispatch, useAppSelector } from "app/hooks";
import { ROUTES } from "constants/routes";
import { GET_USER } from "features/user/UserSaga";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const AuthCallback: React.FC<{ }> = ({ }): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.app.loading["authenticating"]);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const isDispatched = useRef(false);

  useEffect(() => {
    if (!isDispatched.current) {
      dispatch({ type: GET_USER });
      isDispatched.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (loading === false && isAuthenticated) {
      navigate(ROUTES.MY_TRIPS);
    } else if (loading === false && !isAuthenticated) {
      toast.error("Failed to authenticate, please try again later");
      navigate(ROUTES.LOGIN);
    }
  }, [loading, isAuthenticated]);

  return <></>;
}
